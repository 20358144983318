/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//React libraries
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Custom libraries
import { EMPTY_STRING, XS_MOBILE_W } from "../../../constants/common";
import { useSite } from "../../../_foundation/hooks/useSite";
//Redux
import { loginStatusSelector } from "../../../redux/selectors/user";
import { getWishListSelector } from "../../../redux/selectors/wish-list";
import * as wishListActions from "../../../redux/actions/wish-list";
//UI
import { StyledTooltip } from "@hcl-commerce-store-sdk/react-component";
import styled from "styled-components";
import wishListService from "../../../_foundation/apis/transaction/wishList.service";

const cancels: Canceler[] = [];

export const AddToWishListButton = ({ selectedSku, disabled, addFn, removeFn }) => {
  const loginStatus = useSelector(loginStatusSelector);
  const userWishList = useSelector(getWishListSelector);
  let wishList: any;
  const [isAlreadyClicked, setIsAlreadyClicked] = useState<boolean>(false);
  const [isAddedtoList, setAddedToList] = useState<boolean>(false);
  const { t } = useTranslation();

  const { mySite } = useSite();
  const visible = loginStatus && !mySite?.isB2B;
  const [selectedWL, setSelectedWL] = useState<any>();
  const ref = useRef<HTMLElement>();

  const dispatch = useDispatch();
  const widgetName = useMemo(() => {
    return getDisplayName("AddToWishListButton");
  }, []);
  const CancelToken = useMemo(() => {
    return Axios.CancelToken;
  }, []);
  const getList = useCallback(async () => {
    dispatch(
      wishListActions.GET_USER_WISHLIST_ACTION({
        widget: widgetName,
        cancelToken: new CancelToken((c) => cancels.push(c)),
      })
    );
  }, [dispatch, widgetName, CancelToken]);

  const selectWL = async (event) => {
    if (isAlreadyClicked) return;
    setIsAlreadyClicked(true);
    if (!wishList && userWishList != null && userWishList.length > 0) {
      const columns = userWishList.map((wl: any, index: number) => {
        if (wl.x_field1 != "1") {
          wishList = wl;
        }
      });
    }
    if (!wishList) {
      const widget = "useWishList";
      await createWishlist();
      const storeId: string = mySite ? mySite.storeID : "";
      const payloadBase: any = {
        storeId: storeId,
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      const res = await wishListService.findWishlist(payloadBase);
      if (res.data.GiftList && res.data.GiftList.length > 0) {
        res.data.GiftList.map((wl: any, index: number) => {
          if (wl.x_field1 != "1") {
            wishList = wl;
          }
        });
      }
    }

    setSelectedWL(wishList);
    if (isAddedtoList) {
      setAddedToList(await removeFn(wishList));
    } else {
      setAddedToList(await addFn(wishList));
    }
    setIsAlreadyClicked(false);
  };

  useEffect(() => {
    if (visible) {
      getList();
    }
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      cancels.splice(0).forEach((cancel: Canceler) => {
        cancel();
      });
    };
  }, []);
  useEffect(() => {
    if (selectedSku) {
      if (userWishList != null && userWishList.length > 0) {
        setAddedToList(false);
        const columns = userWishList.map((wl: any, index: number) => {
          if (wl.x_field1 != "1") {
            if (wl.item) {
              wl.item.map((gfItem: any) => {
                if (gfItem.productId == selectedSku) {
                  setAddedToList(true);
                }
              });
            }
          }
        });
      }
    }
  }, [selectedSku, userWishList]);
  return (
    <>
      {visible ? (
        <div className="wish-section">
          <a
            href="javascript:void(0)"
            onClick={(e) => {
              e.preventDefault();
              selectWL(e);
            }}
            title="">
            <span id={selectedSku} className="material-icons-outlined">
              {isAddedtoList ? "favorite" : "favorite_border"}
            </span>
          </a>
        </div>
      ) : null}
    </>
  );
};
async function createWishlist() {
  const widget = "createList";
  const params = {
    body: { description: "Favourite", registry: false },
    widget,
  };
  await wishListService
    .createWishlist(params)
    .then((res) => res.data)
    .then((result) => {
      console.log("list created");
    });
}
