/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
  StyledLink,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";

interface TwoTierMenuProps {
  page?: any;
}

/**
 * TwoTierMenu component
 * expanded menu two tier submenu
 * @param props
 */
const TwoTierMenu: React.FC<TwoTierMenuProps> = (props: any) => {
  const page = props.page ? props.page : [];
  const [hover, setHover] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [cols, setcols] = useState(4);
  const toggleHover = (state) => {
    setHover(state);
    console.log(hover);
  };
  console.log(page.children, "page.children1 ");
  useEffect(() => {
    if (page.name === "New") {
      setcols(3);
    } else if (page.name === "Sports") {
      setcols(5);
    } else {
      setcols(4);
    }
  }, [page.name]);

  const res = [...Array(cols).keys()].map((c) => page.children?.filter((_, i) => i % cols === c));
  console.log(res, "page.res");

  return (
    <>
      <StyledPaper className="expanded-menu-two-tier-submenu expanded-menu-Whpr">
        <StyledGrid m={3} item className="expandedMainMenu" md={11} xl={8}>
          <StyledLink
            testId={`header-${page.id}`}
            to={page.seo?.href}
            className="header-Link"
            state={{
              breadCrumbTrailEntryView: [{ label: page.name, value: page.id, seo: page.seo }],
            }}>
            <StyledMenuTypography variant="body1" className="expanded-menu-bold ">
              VIEW ALL {page.name}
            </StyledMenuTypography>
          </StyledLink>

          <StyledGrid container className="twotireMenuConatiner justify-center">
            {res &&
              res?.map((page1, index) => (
                <StyledGrid item className="twotireMenu" md={2} xl={2}>
                  {page1 &&
                    page1.map((page2) => (
                      <>
                        <div
                          key={index}
                          className={`subMenuHeaderTiertwo ${selectedId === page2.id && hover ? "active" : ""}`}
                          onMouseEnter={() => {
                            toggleHover(true);
                            setSelectedId(page2.id);
                            console.log(selectedId);
                          }}
                          onMouseLeave={() => {
                            toggleHover(false);
                            setSelectedId(-1);
                            console.log(selectedId);
                          }}>
                          <StyledLink
                            key={page2.id}
                            testId={`header-${page2.id}`}
                            to={page2.seo?.href}
                            state={{
                              breadCrumbTrailEntryView: [
                                { label: page.name, value: page.id, seo: page.seo },
                                { label: page2.name, value: page2.id, seo: page2.seo },
                              ],
                            }}>
                            <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                              {page2.name}
                            </StyledMenuTypography>
                          </StyledLink>
                        </div>
                      </>
                    ))}
                </StyledGrid>
              ))}
          </StyledGrid>
        </StyledGrid>
      </StyledPaper>
    </>
  );
};

export default TwoTierMenu;
