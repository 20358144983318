/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { useState } from "react";
//UI

/**
 * ThreeTierMenu component
 * expanded menu three tier submenu
 * @param props
 */

const ShopByBrand = () => {
  return (
    <>
      {/* <div className="brand-shop-wrapper">
        <p className="white-text">Shop by brand</p>
        <div className="brand-name-wpr">
          <button className="black-btn">
            {" "}
            <img
              src="https://static.augustasportswear.com/static/footer/DSK_logo_augusta.svg"
              alt="Augusta Sportswear brand Logo"
              id="augutalogo-f"
              className="brandlogo solologo"
            />
          </button>
          <button className="black-btn">
            {" "}
            <img
              src="https://static.augustasportswear.com/static/footer/DSK_logo_holloway.svg"
              alt="Holloway brand Logo"
              id="hollowaylogo-f"
              className="brandlogo solologo"
            />
          </button>
          <button className="black-btn">
            {" "}
            <img
              src="https://static.augustasportswear.com/static/footer/DSK_logo_high5.svg"
              alt="High Five brand logo"
              id="highgfive-f"
              className="brandlogo solologo"
            />
          </button>
          <button className="black-btn">
            <img
              src="https://static.augustasportswear.com/static/footer/DSK_logo_pacific.svg"
              alt="Pacific Headwear Logo"
              id="pacificlogo-f"
              className="brandlogo solologo"
            />
          </button>
          <button className="black-btn">
            {" "}
            <img
              src="https://static.augustasportswear.com/static/footer/DSK_logo_russell.svg"
              alt="Russel Athletics Brand Logo"
              id="russelllogo-f"
              className="brandlogo solologo"
            />
          </button>
          <button className="black-btn">
            {" "}
            <img
              src="https://static.augustasportswear.com/static/footer/DSK_logo_ccm.svg"
              alt="CCM Brand Logo"
              id="CCM-f"
              className="brandlogo solologo"
            />
          </button>
        </div>
      </div> */}
    </>
  );
};

export default ShopByBrand;
