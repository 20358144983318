/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

// Standard libraries
import React, { useMemo, useState, useEffect, useContext } from "react";
import { MINICART_CONFIGS } from "../../configs/order";
import { useNavigate } from "react-router";
import {
  StyledGrid,
  StyledContainer,
  StyledProductImage,
  StyledTypography,
  StyledButton,
} from "@hcl-commerce-store-sdk/react-component";
import { useTranslation } from "react-i18next";
import { CART, CHECKOUT, IP_ORDER_DETAILS, SIGNIN } from "../../constants/routes";
import { numItemsSelector, cartSelector, orderItemsSelector } from "../../redux/selectors/order";
import { RESET_SUCCESS_MESSAGE_ACTION } from "../../redux/actions/success";
import { useDispatch, useSelector } from "react-redux";
import FormattedPriceDisplay from "../widgets/formatted-price-display";
interface ProductAddedTocartMsgModalProps {
  timer: number;
  displayPartNumber: string;
}
const ProductAddedTocartMsgModal: React.FC<ProductAddedTocartMsgModalProps> = ({ timer, displayPartNumber }) => {
  const { t } = useTranslation();
  const numItems = useSelector(numItemsSelector);
  const navigate = useNavigate();
  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const miniCartItems = findItemByPartNumber(orderItems, displayPartNumber);

  const filtersizeValue = "asgswatchsize";
  const filtersizeobject = miniCartItems
    ? miniCartItems?.attributes?.filter((val) => val.identifier.includes(filtersizeValue))
    : [];
  const filtersize = filtersizeobject[0] ? JSON.parse(filtersizeobject[0]["attribute.natural"].trim()).toString() : "";
  const filcolValue = "asgswatchcolor";
  const filcolobject = miniCartItems
    ? miniCartItems?.attributes.filter((val) => val.identifier.includes(filcolValue))
    : [];
  const filcolor = filcolobject[1] ? JSON.parse(filcolobject[1]["attribute.natural"].trim()).toString() : "";
  const dispatch = useDispatch();
  const handleViewCart = () => {
    dispatch(RESET_SUCCESS_MESSAGE_ACTION());
    navigate(CART);
  };
  const handleCheckout = () => {
    dispatch(RESET_SUCCESS_MESSAGE_ACTION());
    navigate(CHECKOUT);
  };
  function findItemByPartNumber(orderItems, displayPartNumber) {
    for (const item of orderItems) {
      if (item.partNumber === displayPartNumber) {
        return item;
      }
    }
  }
  return (
    <>
      <StyledGrid className="add-to-cart-wpr padding-10">
        <StyledGrid container direction="row" className="margin-bottom-7">
          {/*<StyledGrid item xs={12} md={5}>
            <StyledTypography variant="h6" className="margin-bottom-7 padding-right-10 capitalize">
              Excellent Choice !!!
            </StyledTypography>
          </StyledGrid>*/}
          <StyledGrid item xs={12} md={12}>
            <StyledTypography className="margin-bottom-7 green">Item added to your cart</StyledTypography>
          </StyledGrid>
          {/*<StyledGrid item xs={12} md={12}>
           <StyledTypography className="margin-bottom-7 green italic">
              you are just $16(static) away from free shipping !
            </StyledTypography>
          </StyledGrid>*/}
        </StyledGrid>
        <StyledGrid container item direction="row" className="text-center margin-bottom-7" spacing={2}>
          <StyledGrid item xs={12} md={2} className="product-img">
            <StyledProductImage
              itemProp="image"
              src={miniCartItems?.thumbnail.replace(".jpg", "_aws_640.jpg")}
              alt={"image"}
              className={"image width-full"}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={9} className="text-left margin-bottom-7">
            <StyledGrid item xs={12} md={12} className="margin-bottom-7">
              <StyledTypography variant="h5" className="brndNmeTxt ">
                {miniCartItems?.name}
              </StyledTypography>
            </StyledGrid>

            <StyledGrid container item direction="row" xs={12} md={12} className="margin-bottom-7">
              <StyledGrid item xs={12} md={6}>
                <StyledGrid item xs={12} md={12} className="margin-bottom-7">
                  <span className="text-title-grey">Color:</span>
                  <span className="text-bold">&nbsp;{filcolor}</span>
                </StyledGrid>
                <StyledGrid item xs={12} md={12} className="margin-bottom-7">
                  <span className="text-title-grey">Qty:</span>
                  <span className="text-bold">&nbsp;{Math.round(miniCartItems?.quantity)}</span>
                </StyledGrid>
              </StyledGrid>
              <StyledGrid item xs={12} md={6}>
                <StyledGrid item xs={12} md={12} className="margin-bottom-7">
                  <span className="text-title-grey">Size:</span>
                  <span className="text-bold">&nbsp;{filtersize}</span>
                </StyledGrid>
                <StyledGrid item xs={12} md={12}>
                  <span className="text-title-grey">Price:</span>
                  <span className="text-bold green">
                    &nbsp;
                    <FormattedPriceDisplay min={miniCartItems?.orderItemPrice} />
                  </span>
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
        {/* <div className="product-added-modal">
          <div>Continue: {timer}</div>
        </div> */}
        <StyledGrid container item direction="row" justifyContent="space-between" className="margin-bottom-7">
          <StyledGrid item xs={12} md={4} className="margin-bottom-7">
            <StyledButton testId="apply-filter-mobile" color="secondary" fullWidth onClick={handleViewCart}>
              VIEW CART
            </StyledButton>
          </StyledGrid>
          <StyledGrid item xs={12} md={4} className="margin-bottom-7">
            <StyledButton testId="apply-filter-mobile" color="primary" fullWidth onClick={handleCheckout}>
              CHECKOUT
            </StyledButton>
          </StyledGrid>
        </StyledGrid>
        {/* <StyledGrid>You may also like section</StyledGrid> */}
      </StyledGrid>
    </>
  );
};

export default ProductAddedTocartMsgModal;
