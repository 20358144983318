/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const footerConfig = {
  page: {
    name: "footer",
  },
  espot: {
    footerLogoESpotName: "ASBFooterStoreLogo",
    footerContentEspotName: "ASBFooter",
  },
};
