/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
import { kebabCase } from "lodash-es";
//Foundation
import { useSite } from "../../_foundation/hooks/useSite";
//UI
import Drawer from "@material-ui/core/Drawer";
import {
  StyledMenuItem,
  StyledMenuTypography,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledLink,
  StyledGrid,
  StyledButton,
  StyledTypography,
  StyledSwipeableDrawer,
} from "@hcl-commerce-store-sdk/react-component";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
//GA360
import AsyncCall from "../../_foundation/gtm/async.service";

const MegaMenuLink = (props: any) => {
  const {
    name,
    link,
    page,
    level,
    activeMenuId,
    setActiveMenuId,
    activeParentMenuId,
    setActiveParentMenuId,
    closeMegaMenu,
    parentId,
    parentName,
    site,
  } = props;

  const onClick = () => {
    //GA360
    if (site.enableGA) AsyncCall.sendNavigationClick(parentName, name);
    closeMegaMenu();
  };

  return (
    <>
      {page.children && page.children.length > 0 ? (
        <>
          <MegaMenuColumn
            page={page}
            activeMenuId={activeMenuId}
            setActiveMenuId={setActiveMenuId}
            activeParentMenuId={activeParentMenuId}
            setActiveParentMenuId={setActiveParentMenuId}
            closeMegaMenu={closeMegaMenu}
            level={level + 1}
            parentId={parentId}
            breadcrumbs={link.state?.breadCrumbTrailEntryView ?? []}
          />
        </>
      ) : (
        <StyledLink to={link.pathname} onClick={onClick} className="mega-mobile-menu-link">
          <StyledMenuItem role="menuitem" className="border-bottom margin-bottom-5 ">
            <StyledMenuTypography variant={"overline"}>
              <span>{name} </span>
              <span className="material-icons-outlined close-position">navigate_next</span>
            </StyledMenuTypography>
            <span></span>
          </StyledMenuItem>
        </StyledLink>
      )}
    </>
  );
};

interface MegaMenuColumnProps {
  page: any;
  activeMenuId: number | undefined;
  setActiveMenuId: any;
  activeParentMenuId: number | undefined;
  setActiveParentMenuId: any;
  closeMegaMenu: any;
  level: number;
  parentId: number | undefined;
  breadcrumbs?: any[];
}

/**
 * MegaMenu component
 * displays top category links in desktop/mobile view
 * @param props
 */
const MegaMenuColumn: React.FC<MegaMenuColumnProps> = (props: any) => {
  const {
    page,
    activeMenuId,
    setActiveMenuId,
    activeParentMenuId,
    setActiveParentMenuId,
    closeMegaMenu,
    level,
    breadcrumbs = [],
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const id = page.id;
  const { mySite } = useSite();

  const childrenList: JSX.Element[] = [];

  if (page.children && page.children.length > 0) {
    page.children.map(function (childPage: any, index: number) {
      const element = (
        <MegaMenuLink
          key={childPage.id}
          link={{
            pathname: childPage.seo?.href,
            state: {
              breadCrumbTrailEntryView: [
                ...breadcrumbs,
                { label: page.name, value: page.id, seo: page.seo },
                {
                  label: childPage.name,
                  value: childPage.id,
                  seo: childPage.seo,
                },
              ],
            },
          }}
          id={id}
          name={childPage.name}
          page={childPage}
          activeMenuId={activeMenuId}
          setActiveMenuId={setActiveMenuId}
          activeParentMenuId={activeParentMenuId}
          setActiveParentMenuId={setActiveParentMenuId}
          closeMegaMenu={closeMegaMenu}
          level={level + 1}
          parentId={id}
          parentName={props.page.name}
          site={mySite}
        />
      );
      childrenList.push(element);
      return null;
    });
  }

  const icon = useMediaQuery(theme.breakpoints.down("xs")) ? <ExpandMoreIcon /> : "";

  const onChange = () => {
    setActiveMenuId(activeMenuId !== id ? id : null);
    setActiveParentMenuId(activeParentMenuId !== id ? id : null);
  };
  const onClick = () => {
    //GA360
    if (mySite.enableGA) {
      AsyncCall.sendNavigationClick(
        { eventAction: "Main", eventLabel: page.name },
        {
          enableUA: mySite.enableUA,
          enableGA4: mySite.enableGA4,
        }
      );
    }
    closeMegaMenu();
  };
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  const turnOffOpen = () => setOpen(false);
  const turnOnOpen = () => setOpen(true);

  const closedrawer = () => {
    setOpen(!open);
    closeMegaMenu();
  };
  return (
    <>
      <div>
        <div
          className={`level-${level}`}
          aria-controls={`${id}bh-content`}
          id={`${id}bh-header`}
          onClick={() => setOpen(!open)}>
          <div className="margin-top-20">
            <StyledMenuItem className="border-bottom margin-bottom-5 ">
              <>
                <StyledMenuTypography
                  variant={"overline"}
                  className="category-title"
                  id={`megamenu_department_${page.id}`}
                  title={page.name}>
                  {page.name}
                </StyledMenuTypography>
                <span className="material-icons-outlined close-position">navigate_next</span>
              </>
            </StyledMenuItem>
          </div>
        </div>

        {/* <Drawer anchor="left" open={open} onClose={() => setOpen(!open)} className="header-drawer">
          <StyledGrid container justifyContent="center flex-start">
            <StyledGrid className="flex align-end">
              <span className="material-icons-outlined" onClick={() => setOpen(!open)}>
                chevron_left
              </span>
              <StyledTypography tabIndex="0" variant="h4" className="vertical-margin-4">
                {page.name}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid className="close-icon  text-right close-position">
              <span className="material-icons-outlined" onClick={() => setOpen(!open)}>
                close
              </span>
            </StyledGrid>
          </StyledGrid>
          <div className="mega-Menu-MobileWpr " aria-controls={`${id}bh-content`}>
            {childrenList}
          </div>
        </Drawer> */}
        <StyledSwipeableDrawer open={open} onClose={turnOffOpen} onOpen={turnOnOpen} className="header-drawer">
          <StyledGrid container justifyContent="center">
            <StyledGrid className="flex align-end">
              <span className="material-icons-outlined" onClick={() => setOpen(!open)}>
                chevron_left
              </span>
              <StyledTypography tabIndex="0" variant="h4" className="vertical-margin-4">
                {page.name}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid className="close-icon  text-right close-position">
              <span className="material-icons-outlined" onClick={() => setOpen(!open)}>
                close
              </span>
            </StyledGrid>
          </StyledGrid>
          <StyledLink
            className="header-Link mob-view-all"
            onClick={() => closedrawer()}
            testId={`header-${page.id}`}
            to={page.seo?.href}
            state={{
              breadCrumbTrailEntryView: [{ label: page.name, value: page.id, seo: page.seo }],
            }}>
            <StyledMenuTypography variant="body1" className="expanded-menu-bold text-uppercase ">
              VIEW ALL {page.name}
            </StyledMenuTypography>
          </StyledLink>
          <div className="mega-Menu-MobileWpr ">{childrenList}</div>
        </StyledSwipeableDrawer>
      </div>
    </>
  );
};

export default MegaMenuColumn;
