/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import { SEOReducerState } from "./reducerStateInterface";
import { GET_SEO_CONFIG_SUCCESS_ACTION } from "../actions/seo";
import { PAGE_TYPE } from "../../_foundation/constants/common";

const getSEOConfigFromPayload = ({ identifier, seoconfig }) => {
  seoconfig.page["tokenValue"] = seoconfig.tokenValue;
  seoconfig.page["tokenName"] = seoconfig.tokenName;
  seoconfig.page.externalContext = {
    identifier: seoconfig.tokenExternalValue,
  };
  if (seoconfig.page?.externalContext?.identifier === "HomePage") {
    seoconfig.page["title"] = process.env.REACT_APP_SITE_TITLE;
  } else if (seoconfig.page?.externalContext?.identifier === PAGE_TYPE.CART_PAGE) {
    seoconfig.page["title"] = "Shopping Cart";
  } else if (seoconfig.page?.externalContext?.identifier === PAGE_TYPE.CHECK_OUT_PAGE) {
    seoconfig.page["title"] = "Checkout";
  } else if (seoconfig.page?.externalContext?.identifier === PAGE_TYPE.ORDER_CONFIRMATION_PAGE) {
    seoconfig.page["title"] = "Order Confirmation";
  } else if (seoconfig.page?.name === "CATEGORY_PAGE" || seoconfig.page?.type === PAGE_TYPE.PRODUCT_PAGE) {
    seoconfig.page["title"] = seoconfig.page.title.replace(
      ` | ${process.env.REACT_APP_STORENAME}`,
      ` | ${process.env.REACT_APP_SITE_BRAND}`
    );
  }
  return { [identifier]: seoconfig };
};
/**
 * SEO reducer
 */
const seoReducer = createReducer(initStates.seo, (builder) => {
  builder.addCase(GET_SEO_CONFIG_SUCCESS_ACTION, (state: SEOReducerState | any, action: AnyAction) => {
    const seoconfig = getSEOConfigFromPayload(action.payload);

    // delete all other seo-urls in the state -- no need to cache since we'll refetch them anyway --
    //   keeping them in the cache re-renders the target component twice: once from cache, once from
    //   the refetch, which is terrible for state-wise tracking
    const hasOwn = Object.prototype.hasOwnProperty.bind(state);
    Object.keys(state)
      .filter(hasOwn)
      .forEach((k) => delete state[k]);

    Object.assign(state, seoconfig);
  });
});
export default seoReducer;
