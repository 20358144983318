/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { useState } from "react";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
  StyledLink,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";
import ShopByBrand from "./ShopByBrand";
import { megamenuConfig } from "./../header/MegaMenuConstrant";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { ProductRecommendationWidget } from "@hcl-commerce-store-sdk/react-component";
import { CategoryRecommendationWidget } from "@hcl-commerce-store-sdk/react-component";
import CatalogEntryRecommendationWidget from "../commerce-widgets/catalog-entry-recommendation-widget";
import { paramCase } from "change-case";
import ESpot from "../commerce-widgets/e-marketing-spot-widget";
import { WidgetProps } from "../../_foundation/constants/seo-config";
import { LazyLoadComponent } from "react-lazy-load-image-component";

interface ThreeTierMenuProps {
  page?: any;
  childcount?: any;
}

/**
 * ThreeTierMenu component
 * expanded menu three tier submenu
 * @param props
 */

const ThreeTierMenu: React.FC<ThreeTierMenuProps> = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const childcount = props.childcount;
  const page = props.page ? props.page : [];
  const [hover, setHover] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const toggleHover = (state) => {
    setHover(state);
    console.log(hover);
  };

  const widgetProps: WidgetProps = {
    widget: {
      id: "notfound-produtRec",
      widgetName: "CartPageProductRecommendation",
      name: "CartPageProductRecommendation",
      properties: {
        emsName: "ShoppingCartRight_CatEntries",
      },
    },
    page: { pageName: "header" },
  };
  console.log(page.children, "page.children ");
  return (
    <>
      <StyledPaper className="expanded-menu-three-tier-submenu expanded-menu-Whpr">
        <StyledGrid m={3} className="expandedMainMenu" md={11} xl={8}>
          <StyledLink
            className="header-Link"
            testId={`header-${page.id}`}
            to={page.seo?.href}
            state={{
              breadCrumbTrailEntryView: [{ label: page.name, value: page.id, seo: page.seo }],
            }}>
            <StyledMenuTypography variant="body1" className="expanded-menu-bold">
              VIEW ALL {page.name}
            </StyledMenuTypography>
          </StyledLink>
          {page.children && (
            <StyledBox
              display="flex"
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              className="justify-center"
              alignContent="flex-start">
              <StyledGrid item md={2} xl={2} className="column1 threeMenuWpr">
                {page.children
                  .filter((page2) => page2.name === "Trending")
                  .map((page2: any) => (
                    <>
                      <div className="menu-header-column">
                        <StyledBox mt={2} mr={5} key={page2.id} className="flex subMenuHeader">
                          <div
                            className={`subMenuHeaderTierOne ${selectedId === page2.id && hover ? "active" : ""}`}
                            onMouseEnter={() => {
                              toggleHover(true);
                              setSelectedId(page2.id);
                              console.log(selectedId);
                            }}
                            onMouseLeave={() => {
                              toggleHover(false);
                              setSelectedId(-1);
                              console.log(selectedId);
                            }}>
                            <StyledLink
                              className="expandMenuLink"
                              testId={`header-${page2.id}`}
                              key={"Link_" + page2.id}
                              to={page2.seo?.href}
                              state={{
                                breadCrumbTrailEntryView: [
                                  { label: page.name, value: page.id, seo: page.seo },
                                  {
                                    label: page2.name,
                                    value: page2.id,
                                    seo: page2.seo,
                                  },
                                ],
                              }}>
                              <StyledMenuTypography
                                variant="body2"
                                className={
                                  "expanded-menu-bold " + (childcount === 2 ? "twoTierHeading" : "threeTierHeading")
                                }>
                                {page2.name}
                              </StyledMenuTypography>
                            </StyledLink>
                          </div>
                        </StyledBox>
                        <ul className="subMenuHeaderVal">
                          {page2.children?.map((page3: any) => (
                            <li>
                              <StyledLink
                                testId={`header-${page3.id}`}
                                key={page3.id}
                                to={page3.seo?.href}
                                state={{
                                  breadCrumbTrailEntryView: [
                                    {
                                      label: page.name,
                                      value: page.id,
                                      seo: page.seo,
                                    },
                                    {
                                      label: page2.name,
                                      value: page2.id,
                                      seo: page2.seo,
                                    },
                                    {
                                      label: page3.name,
                                      value: page3.id,
                                      seo: page3.seo,
                                    },
                                  ],
                                }}>
                                <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                                  {page3.name}
                                </StyledMenuTypography>
                              </StyledLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ))}
              </StyledGrid>
              <StyledGrid item md={2} xl={2} className="column2 threeMenuWpr">
                {page.children
                  .filter((page2) => page2.name === "Tops" || page2.name === "Bottoms" || page2.name === "Styles")
                  .map((page2: any) => (
                    <>
                      <div className="menu-header-column">
                        <StyledBox mt={2} mr={5} key={page2.id} className="flex subMenuHeader">
                          <div
                            className={`subMenuHeaderTierOne ${selectedId === page2.id && hover ? "active" : ""}`}
                            onMouseEnter={() => {
                              toggleHover(true);
                              setSelectedId(page2.id);
                              console.log(selectedId);
                            }}
                            onMouseLeave={() => {
                              toggleHover(false);
                              setSelectedId(-1);
                              console.log(selectedId);
                            }}>
                            <StyledLink
                              className="expandMenuLink"
                              testId={`header-${page2.id}`}
                              key={"Link_" + page2.id}
                              to={page2.seo?.href}
                              state={{
                                breadCrumbTrailEntryView: [
                                  { label: page.name, value: page.id, seo: page.seo },
                                  {
                                    label: page2.name,
                                    value: page2.id,
                                    seo: page2.seo,
                                  },
                                ],
                              }}>
                              <StyledMenuTypography
                                variant="body2"
                                className={
                                  "expanded-menu-bold " + (childcount === 2 ? "twoTierHeading" : "threeTierHeading")
                                }>
                                {page2.name}
                              </StyledMenuTypography>
                            </StyledLink>
                          </div>
                        </StyledBox>
                        <ul className="subMenuHeaderVal">
                          {page2.children?.map((page3: any) => (
                            <li>
                              <StyledLink
                                testId={`header-${page3.id}`}
                                key={page3.id}
                                to={page3.seo?.href}
                                state={{
                                  breadCrumbTrailEntryView: [
                                    {
                                      label: page.name,
                                      value: page.id,
                                      seo: page.seo,
                                    },
                                    {
                                      label: page2.name,
                                      value: page2.id,
                                      seo: page2.seo,
                                    },
                                    {
                                      label: page3.name,
                                      value: page3.id,
                                      seo: page3.seo,
                                    },
                                  ],
                                }}>
                                <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                                  {page3.name}
                                </StyledMenuTypography>
                              </StyledLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ))}
              </StyledGrid>
              <StyledGrid item md={2} xl={2} className="column3 threeMenuWpr">
                {page.children
                  .filter((page2) => page2.name === "Outerwear" || page2.name === "Features")
                  .map((page2: any) => (
                    <>
                      <div className="menu-header-column">
                        <StyledBox mt={2} mr={5} key={page2.id} className="flex subMenuHeader">
                          <div
                            className={`subMenuHeaderTierOne ${selectedId === page2.id && hover ? "active" : ""}`}
                            onMouseEnter={() => {
                              toggleHover(true);
                              setSelectedId(page2.id);
                              console.log(selectedId);
                            }}
                            onMouseLeave={() => {
                              toggleHover(false);
                              setSelectedId(-1);
                              console.log(selectedId);
                            }}>
                            <StyledLink
                              className="expandMenuLink"
                              testId={`header-${page2.id}`}
                              key={"Link_" + page2.id}
                              to={page2.seo?.href}
                              state={{
                                breadCrumbTrailEntryView: [
                                  { label: page.name, value: page.id, seo: page.seo },
                                  {
                                    label: page2.name,
                                    value: page2.id,
                                    seo: page2.seo,
                                  },
                                ],
                              }}>
                              <StyledMenuTypography
                                variant="body2"
                                className={
                                  "expanded-menu-bold " + (childcount === 2 ? "twoTierHeading" : "threeTierHeading")
                                }>
                                {page2.name}
                              </StyledMenuTypography>
                            </StyledLink>
                          </div>
                        </StyledBox>
                        <ul className="subMenuHeaderVal">
                          {page2.children?.map((page3: any) => (
                            <li>
                              <StyledLink
                                testId={`header-${page3.id}`}
                                key={page3.id}
                                to={page3.seo?.href}
                                state={{
                                  breadCrumbTrailEntryView: [
                                    {
                                      label: page.name,
                                      value: page.id,
                                      seo: page.seo,
                                    },
                                    {
                                      label: page2.name,
                                      value: page2.id,
                                      seo: page2.seo,
                                    },
                                    {
                                      label: page3.name,
                                      value: page3.id,
                                      seo: page3.seo,
                                    },
                                  ],
                                }}>
                                <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                                  {page3.name}
                                </StyledMenuTypography>
                              </StyledLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ))}
              </StyledGrid>
              {page.name !== "Headwear" ? (
                <StyledGrid item md={2} xl={2} className="column4 threeMenuWpr">
                  {page.children
                    .filter(
                      (page2) =>
                        page2.name === "Shop By Brand" ||
                        page2.name === "Accessories" ||
                        page2.name === "Custom" ||
                        page2.name === "Sale"
                    )
                    .map((page2: any) => (
                      <>
                        <div className="menu-header-column">
                          <StyledBox mt={2} mr={5} key={page2.id} className="flex subMenuHeader">
                            <div
                              className={`subMenuHeaderTierOne ${selectedId === page2.id && hover ? "active" : ""}`}
                              onMouseEnter={() => {
                                toggleHover(true);
                                setSelectedId(page2.id);
                                console.log(selectedId);
                              }}
                              onMouseLeave={() => {
                                toggleHover(false);
                                setSelectedId(-1);
                                console.log(selectedId);
                              }}>
                              <StyledLink
                                className="expandMenuLink"
                                testId={`header-${page2.id}`}
                                key={"Link_" + page2.id}
                                to={page2.seo?.href}
                                state={{
                                  breadCrumbTrailEntryView: [
                                    { label: page.name, value: page.id, seo: page.seo },
                                    {
                                      label: page2.name,
                                      value: page2.id,
                                      seo: page2.seo,
                                    },
                                  ],
                                }}>
                                <StyledMenuTypography
                                  variant="body2"
                                  className={
                                    "expanded-menu-bold " + (childcount === 2 ? "twoTierHeading" : "threeTierHeading")
                                  }>
                                  {page2.name}
                                </StyledMenuTypography>
                              </StyledLink>
                            </div>
                          </StyledBox>
                          <ul className="subMenuHeaderVal">
                            {page2.children?.map((page3: any) => (
                              <li>
                                <StyledLink
                                  testId={`header-${page3.id}`}
                                  key={page3.id}
                                  to={page3.seo?.href}
                                  state={{
                                    breadCrumbTrailEntryView: [
                                      {
                                        label: page.name,
                                        value: page.id,
                                        seo: page.seo,
                                      },
                                      {
                                        label: page2.name,
                                        value: page2.id,
                                        seo: page2.seo,
                                      },
                                      {
                                        label: page3.name,
                                        value: page3.id,
                                        seo: page3.seo,
                                      },
                                    ],
                                  }}>
                                  <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                                    {page3.name}
                                  </StyledMenuTypography>
                                </StyledLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    ))}
                </StyledGrid>
              ) : null}
              {/* {page.name === "Adult" || page.name === "Ladies" || page.name === "Youth" ? (
                <StyledGrid item md={2} xl={3} className="column5 threeMenuWpr">
                  {page.children
                    .filter((page2) => page2.name === "Key Collections")
                    .map((page2: any) => (
                      <>
                        <div className="menu-header-column">
                          <StyledBox mt={2} mr={5} key={page2.id} className="flex subMenuHeader">
                            <div
                              className={`subMenuHeaderTierOne ${selectedId === page2.id && hover ? "active" : ""}`}
                              onMouseEnter={() => {
                                toggleHover(true);
                                setSelectedId(page2.id);
                                console.log(selectedId);
                              }}
                              onMouseLeave={() => {
                                toggleHover(false);
                                setSelectedId(-1);
                                console.log(selectedId);
                              }}>
                              <StyledLink
                                className="expandMenuLink"
                                testId={`header-${page2.id}`}
                                key={"Link_" + page2.id}
                                to={page2.seo?.href}
                                state={{
                                  breadCrumbTrailEntryView: [
                                    { label: page.name, value: page.id, seo: page.seo },
                                    {
                                      label: page2.name,
                                      value: page2.id,
                                      seo: page2.seo,
                                    },
                                  ],
                                }}>
                                <StyledMenuTypography
                                  variant="body2"
                                  className={
                                    "expanded-menu-bold " + (childcount === 2 ? "twoTierHeading" : "threeTierHeading")
                                  }>
                                  {page2.name}
                                </StyledMenuTypography>
                              </StyledLink>
                            </div>
                          </StyledBox>
                          <ul className="subMenuHeaderVal">
                            {page2.children?.map((page3: any) => (
                              <li>
                                <StyledLink
                                  testId={`header-${page3.id}`}
                                  key={page3.id}
                                  to={page3.seo?.href}
                                  state={{
                                    breadCrumbTrailEntryView: [
                                      {
                                        label: page.name,
                                        value: page.id,
                                        seo: page.seo,
                                      },
                                      {
                                        label: page2.name,
                                        value: page2.id,
                                        seo: page2.seo,
                                      },
                                      {
                                        label: page3.name,
                                        value: page3.id,
                                        seo: page3.seo,
                                      },
                                    ],
                                  }}>
                                  <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                                    {page3.name}
                                  </StyledMenuTypography>
                                </StyledLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    ))}
                </StyledGrid>
              ) : null} */}
            </StyledBox>
          )}
        </StyledGrid>
        <ShopByBrand />
        {/* <div className="menu-product-widget">
          <CategoryRecommendationWidget
          {...{
            widget: {
              id: `header-${paramCase(megamenuConfig.espot.eSpotName)}`,
              widgetName: "category-recommendation-widget",
              name: megamenuConfig.espot.eSpotName,
              properties: {
                emsName: megamenuConfig.espot.eSpotName,
              },
            },
            page: { name: "" },
          }}></CategoryRecommendationWidget> */}
        {/* <ContentRecommendationWidget
          {...{
            widget: {
              id: `header-${paramCase(megamenuConfig.espot.eSpotName)}`,
              widgetName: "category-recommendation-widget",
              name: megamenuConfig.espot.eSpotName,
              properties: {
                emsName: megamenuConfig.espot.eSpotName,
              },
            },
            page: { name: "" },
          }}></ContentRecommendationWidget> */}

        {/* <CatalogEntryRecommendationWidget
            // cid={`notfound-produtRec`}
            {...widgetProps}
        />
        </div> */}
      </StyledPaper>
    </>
  );
};

export default ThreeTierMenu;
