import Cookies from "universal-cookie";

const searchSpringHeaderService = {
  getSearchSpringSessionInfo() {
    const cookies = new Cookies();
    if (cookies.get("ssSessionIdNamespace") === "" || cookies.get("ssSessionIdNamespace") === undefined) {
      const ssSessionIdNamespace = Math.random().toString(36).substring(2, 12);
      const ssUserId = Math.random().toString(36).substring(2, 12);
      cookies.set("ssSessionIdNamespace", ssSessionIdNamespace, { path: "/" });
      cookies.set("ssUserId", ssUserId, { path: "/" });
    }
  },
};
export default searchSpringHeaderService;
