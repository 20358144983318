/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { paramCase } from "change-case";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { footerConfig } from "./footerConstant";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
//UI
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import Grid from "@material-ui/core/Grid";
import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
  StyledTooltip,
} from "@hcl-commerce-store-sdk/react-component";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
function Footer(props: any) {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const location: any = useLocation();
  const { pathname } = location;
  const reviewPage = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_REVIEW;
  const checkoutShipping = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_SHIPPING;
  const checkoutpayment = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_PAYMENT;

  const Logo = useCallback(() => {
    return mySite != null ? (
      <div className="footer-logo">
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `footer-${paramCase(footerConfig.espot.footerLogoESpotName)}`,
              widgetName: "content-recommendation-widget",
              name: footerConfig.espot.footerLogoESpotName,
              properties: {
                emsName: footerConfig.espot.footerLogoESpotName,
              },
            },
            page: { name: "" },
          }}></ContentRecommendationWidget>
      </div>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);

  const CustomerServiceLinks = () => {
    const customerServiceLinks = [
      {
        linkDescription: t("Footer.CustomerService.ContactUsDescription"),
        linkText: t("Footer.CustomerService.ContactUs"),
        linkURL: "contact-us",
      },
      {
        linkText: t("Footer.CustomerService.PrivacyPolicy"),
        linkURL: "privacy-policy",
      },
    ];
    return (
      <div className="footer-nav">
        <p className="font-bold">Customer support</p>

        <StyledTypography variant="caption">
          {customerServiceLinks.map((v: any) => (
            <div key={v.linkURL}>
              <StyledTooltip title={`${t("Footer.DisabledMessage")}`}>
                <Link
                  key={v.linkURL}
                  data-testid={v.linkURL}
                  // to={`/${v.linkURL}`}
                  to="/"
                  onClick={(event) => event.preventDefault()}
                  // title={ v["linkDescription"] ? v["linkDescription"] : v.linkText}
                >
                  {v.linkText}
                </Link>
              </StyledTooltip>
            </div>
          ))}
        </StyledTypography>
      </div>
    );
  };
  const ShopServiceLinks = () => {
    const shopServiceLinks = [
      {
        linkDescription: "Shop",
        linkText: "men",
        linkURL: "men",
      },
      {
        linkDescription: "Shop",
        linkText: "Women",
        linkURL: "men",
      },
      {
        linkDescription: "Shop",
        linkText: "Kids",
        linkURL: "men",
      },
      {
        linkDescription: "Shop",
        linkText: "sports",
        linkURL: "men",
      },
    ];
    return (
      <div className="footer-nav">
        <p className="font-bold">Shop</p>
        <StyledTypography variant="caption">
          {shopServiceLinks.map((v: any) => (
            <div key={v.linkURL}>
              <StyledTooltip title={`${t("Footer.DisabledMessage")}`}>
                <Link
                  key={v.linkURL}
                  data-testid={v.linkURL}
                  // to={`/${v.linkURL}`}
                  to="/"
                  onClick={(event) => event.preventDefault()}
                  // title={ v["linkDescription"] ? v["linkDescription"] : v.linkText}
                >
                  {v.linkText}
                </Link>
              </StyledTooltip>
            </div>
          ))}
        </StyledTypography>
      </div>
    );
  };
  const CompanyLinks = () => {
    const companyLinks = [
      {
        linkText: t("Footer.CompanyLinks.OurStory"),
        linkURL: "our-story",
      },
      {
        linkText: t("Footer.CompanyLinks.Careers"),
        linkURL: "careers",
      },
    ];
    return (
      <div className="contact-us">
        <p className="font-bold">Company</p>
        <StyledTypography variant="caption">
          {companyLinks.map((v: any) => (
            <div key={v.linkURL}>
              <StyledTooltip title={`${t("Footer.DisabledMessage")}`}>
                <Link data-testid={v.linkURL} key={v.linkURL} to="/" onClick={(event) => event.preventDefault()}>
                  {v.linkText}
                </Link>
              </StyledTooltip>
            </div>
          ))}
        </StyledTypography>
      </div>
    );
  };

  const Copyright = () => {
    return (
      <div className="copyright">
        <StyledTypography variant="caption">
          &copy; <span>{new Date().getFullYear()}</span>
          <span> {t("Footer.Copyright")}</span>
        </StyledTypography>
      </div>
    );
  };

  return (
    <>
      {!reviewPage && !checkoutpayment && !checkoutShipping && (
        <div className="footer-wrapper">
          <StyledFooter>
            <ContentRecommendationWidget
              {...{
                widget: {
                  id: `footer-${paramCase(footerConfig.espot.footerContentEspotName)}`,
                  widgetName: "content-recommendation-widget",
                  name: footerConfig.espot.footerContentEspotName,
                  properties: {
                    emsName: footerConfig.espot.footerContentEspotName,
                  },
                },
                page: { name: "" },
              }}></ContentRecommendationWidget>

            {/* <Grid container spacing={0} justifyContent="center" alignItems="center" className="footer-info">
              <Grid item xs={6} md={2} className="Footer-QuickSupport">
                <div className="footer-delivery text-align-center">
                  <span className="material-icons-outlined foot-icons">local_shipping</span>
                  <p className="font-bold">Free delivery</p>
                  <p>On order over $75</p>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="footer-assignment_return text-align-center">
                  <span className="material-icons-outlined foot-icons">assignment_return</span>
                  <p className="font-bold">Easy return</p>
                  <p>45 days for return on eligible items</p>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="footer-payments text-align-center">
                  <span className="material-icons-outlined foot-icons">payments</span>
                  <p className="font-bold">Secure Payment</p>
                  <p>100% Secure Payment</p>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="footer-thumpUP text-align-center">
                  <span className="material-icons-outlined foot-icons">thumb_up</span>
                  <p className="font-bold">Customer Support</p>
                  <p>From 8 AM - 5 PM</p>
                </div>
              </Grid>
            </Grid>

            <StyledContainer className="footer-second">
              <StyledGrid container spacing={2} className="footer-top">
                <StyledGrid item xs={6} sm={4} lg={2}>
                  <CustomerServiceLinks />
                </StyledGrid>
                <StyledGrid item xs={6} sm={4} lg={2}>
                  <ShopServiceLinks />
                </StyledGrid>
                <StyledGrid item xs={6} sm={4} lg={2}>
                  <CompanyLinks />
                </StyledGrid>
                <StyledGrid item xs={6} sm={4} lg={2}>
                  <div className="footer-signup">
                    <p>Sign up for speical offers</p>
                    <small>Your detials are safe with us</small>
                    <div className="input-Wrapperr magrin-10 bottom-margin-2 top-margin-2">
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        className="input-Style black"
                        name="username"
                      />
                      <span className="material-icons-outlined input-arrow">arrow_right_alt</span>
                    </div>
                  </div>
                  <div>
                    <StyledTypography variant="caption">{t("Footer.FollowUs")}</StyledTypography>
                  </div>
                  <div className="footer-social-link">
                    <FacebookIcon />
                  </div>
                  <div className="footer-social-link">
                    <TwitterIcon />
                  </div>
                  <div className="footer-social-link">
                    <InstagramIcon />
                  </div>
                </StyledGrid>
              </StyledGrid>
              <StyledGrid container className="footer-bottom">
                <StyledGrid item xs={12} className="text-align-center">
                  <Copyright />
                </StyledGrid>
              </StyledGrid>
            </StyledContainer> */}
          </StyledFooter>
        </div>
      )}
      {(reviewPage || checkoutpayment || checkoutShipping) && (
        <div className="footer-wrapper checkout-footer">
          <div className="checkout-footer-heading">
            <span>@2023</span>
            <span className="text-bold">Augustaactive</span>
            <span>All Rights Reserved</span>
          </div>
        </div>
      )}
      <SessionErrorDialog />
      <ConfirmationDialog />
    </>
  );
}

export default React.memo(Footer);
