/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import axios, { AxiosRequestConfig, Method } from "axios";
import { getSite } from "../../hooks/useSite";

const customService = {
  getSearchSpringResultsPLP(params: any): Promise<any> {
    const method: Method = "GET";
    const headers = params.headers;
    const SearchspringPLPResponse: AxiosRequestConfig = {
      headers: headers,
      method: method,
      url: params.searchSpringApiUrl,
    };
    return axios(SearchspringPLPResponse);
  },

  getCategoryHierarchy(params: any): any {
    const siteInfo = getSite();
    const method: Method = "GET";
    const headers = params.headers;
    const categoryId = params.categoryId;
    let hostName = window.location.hostname;
    const port = window.location.port;
    hostName = hostName + ":" + port;
    let catalogId: any;
    if (hostName.indexOf("local") !== -1) catalogId = "3074457345616678768";
    else catalogId = siteInfo?.catalogID;
    const protocol = window.location.protocol;
    const storeId = siteInfo?.storeID;
    const reqUrl =
      protocol +
      "//" +
      hostName +
      "/search/resources/store/" +
      storeId +
      "/productview/byCategory/" +
      categoryId +
      "?catalogId=" +
      catalogId +
      "&langId=-1&profileName=IBM_BreadCrumbByCategoryUniqueId";
    const categoryHierarchyResponse: AxiosRequestConfig = {
      headers: headers,
      method: method,
      url: reqUrl,
    };
    return axios(categoryHierarchyResponse);
  },

  getSearchSpringAutoSuggestionKeywords(params: any): any {
    const method: Method = "POST";
    const keywordResponse: AxiosRequestConfig = {
      data: {
        siteId: params.siteId, //jzy6i7
        query: params.term,
      },
      method: method,
      url: params.url,
    };
    return axios(keywordResponse);
  },

  getSearchSpringSuggestionProducts(params: any): Promise<any> {
    const method: Method = "GET";
    const autoSuggResponse: AxiosRequestConfig = {
      headers: params.headers,
      method: method,
      params: params.data,
      url: params.url,
    };
    return axios(autoSuggResponse);
  },

  getASBEnvPropDetails(): Promise<any> {
    const method: Method = "GET";
    const siteInfo = getSite();
    const storeId = siteInfo?.storeID;
    const protocol = window.location.protocol;
    let hostName = window.location.hostname;
    const port = window.location.port;
    hostName = hostName + ":" + port;
    const reqUrl =
      protocol +
      "//" +
      hostName +
      "/wcs/resources/store/" +
      storeId +
      "/asbenvprop/getEnvPropertyDetails?responseFormat=json";

    const response: AxiosRequestConfig = {
      method: method,
      url: reqUrl,
    };
    return axios(response);
  },
};

export default customService;
