/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import AsyncCall from "../../_foundation/gtm/async.service";
import { cartSelector, orderItemsSelector } from "../../redux/selectors/order";

import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { paramCase } from "change-case";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import { STORELOCATORACTIONS } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { useStoreLocatorValue } from "../../_foundation/context/store-locator-context";

//Custom libraries
import { headerConfig } from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import MiniCart from "./MiniCart";
import LanguageToggle from "./LanguageToggle";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "./ExpandedMenu";
import { SearchBar } from "../widgets/search-bar";
import AccountPopperContent from "./AccountPopperContent";
import { CURRENT_USER } from "../../_foundation/constants/common";

//Redux
import { userNameSelector, loginStatusSelector, loginDetailSelector } from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { UPDATE_CATEGORIES_STATE_ACTION } from "../../redux/actions/category";
import { SELLERS_GET_ACTION } from "../../redux/actions/sellers";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";
import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
//UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StoreIcon from "@material-ui/icons/Store";
import { Hidden } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Link from "@material-ui/core/Link";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
  StyledAccountPopper,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledHeaderActions,
  StyledTypography,
  StyledSwipeableDrawer,
  StyledGrid,
  StyledLink,
  StyledPaper,
  StyledBox,
  StyledSearchBarButton,
} from "@hcl-commerce-store-sdk/react-component";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { selectedSellersSelector, sellersSelector } from "../../redux/selectors/sellers";
import { SELLER_STORAGE_KEY } from "../../constants/common";
import { SET_SELLER_ACTION } from "../../redux/actions/sellers";
import { Sellers } from "./sellers";
import { StyledList } from "@hcl-commerce-store-sdk/react-component";
import { StoreLocatorButton } from "./store-locator-button";
import { includes } from "lodash-es";

interface HeaderProps {
  loggedIn: boolean;
  toggleClass: any;
  toggleCloseClass: any;
}

const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton testId={testId} className="header-actionsButton" variant="text" color="secondary" {...props}>
      <StyledHeaderActions>{children}</StyledHeaderActions>{" "}
    </StyledButton>
  );
};

const MarketplacePopper = ({ sellerConfig }) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const guestSellerPicker = "guestSellerPicker";
  const { t } = useTranslation();
  const { mySite } = useSite();
  const onClickAway = useCallback((e) => {
    if (e.target.localName === "body") {
      return;
    }
    setOpen(false);
  }, []);

  return !mySite?.isB2B && sellerConfig.showSellerList ? (
    <>
      <div ref={btnRef}>
        <Buttonify testId="header-mp" onClick={setOpen.bind(null, !open)}>
          <StyledBox display="flex" flexDirection="column" alignItems="center" flexWrap="wrap">
            <StoreIcon />
            <StyledTypography variant="body1" component="p">
              {t("sellers.mp")}
            </StyledTypography>
          </StyledBox>
        </Buttonify>
      </div>
      <StyledAccountPopper
        id={guestSellerPicker}
        open={open}
        anchorEl={btnRef.current}
        placement="bottom-end"
        modifiers={{
          flip: { enabled: false },
          preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
          hide: { enabled: false },
        }}
        className="account-popper">
        <ClickAwayListener onClickAway={onClickAway}>
          <StyledPaper className="vertical-padding-1 horizontal-padding-1">
            <StyledList disablePadding>
              <Sellers />
            </StyledList>
          </StyledPaper>
        </ClickAwayListener>
      </StyledAccountPopper>
    </>
  ) : null;
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any, widget: any) => {
  const location: any = useLocation();
  const { pathname } = location;
  const reviewPage = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_REVIEW;
  const checkoutShipping = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_SHIPPING;
  const checkoutpayment = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_PAYMENT;
  const cartPage = useLocation().pathname === ROUTES.CART;
  const widgetName = getDisplayName(Header);
  const { w } = useWinDimsInEM();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const [myAccountPopperOpen, setMyAccountPopperOpen] = useState<boolean>(false);
  const [showaccountOverlay, setaccountOverlay] = useState<boolean>(false);

  const myAccountElRef = useRef<HTMLButtonElement>(null);

  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const miniCartElRef = useRef<HTMLButtonElement>(null);

  const [languageTogglePopperOpen, setLanguageTogglePopperOpen] = useState<boolean>(false);

  const languageToggleElRef = useRef<HTMLButtonElement>(null);

  const { mySite } = useSite();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);

  const addressDetails = useSelector(addressDetailsSelector);
  const userName = useSelector(userNameSelector);
  const firstName = addressDetails?.firstName ?? userName?.firstName;
  const lastName = addressDetails?.lastName ?? userName?.lastName;
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const userDetails = useSelector(loginDetailSelector);
  const selectedSellers = useSelector(selectedSellersSelector);
  const sellerConfig = useSelector(sellersSelector);
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);

  const userPreviousLoggedIn = useRef();

  const isB2B = Boolean(mySite?.isB2B);
  const { toggleClass, toggleCloseClass } = props;
  const loggedIn = props.loggedIn;
  const isShoppingEnabled = !isB2B || (isB2B && loggedIn);

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = !useMediaQuery(theme.breakpoints.up("md"));

  const myAccountPopperId = "HEADER_MY_ACCOUNT_Popper";
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  const languageTogglePopperId = "HEADER_LANGUAGE_TOGGLE_Popper";
  const CancelToken = Axios.CancelToken;
  const emptyList = useState([]);
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };
  const storeLocatorDispach = useStoreLocatorValue().dispatch;
  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
    setLanguageTogglePopperOpen(false);
    setaccountOverlay(true);
  };
  const handleMyAccountPopperClose = () => {
    setMyAccountPopperOpen(false);
    setaccountOverlay(false);
  };
  const handleViewCart = () => {
    navigate(ROUTES.CART);
  };
  const handleMiniCartClick = () => {
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    setLanguageTogglePopperOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (miniCartElRef !== null && miniCartElRef.current !== null) {
        miniCartElRef.current.focus();
      }
    }, 100);
  };

  const handleMiniCartPopperClose = useCallback(() => setMiniCartPopperOpen(false), []);

  const handleLanguageToggleClick = () => {
    setLanguageTogglePopperOpen(true);
    setMiniCartPopperOpen(false);
    setMyAccountPopperOpen(false);
  };
  const handleLanguageTogglePopperClose = () => {
    setLanguageTogglePopperOpen(false);
  };

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        ...payload,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleContractChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        ...payloadBase,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const param: any = {
      ...payload,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
    storeLocatorDispach({ type: STORELOCATORACTIONS.RESET_STORE_SELECTOR });
  };

  const accountoverlay = (event) => {
    setMyAccountPopperOpen(false);
    setaccountOverlay(false);
  };
  useEffect(() => {
    const flag = sessionStorage.getItem("GTMDTCflag");
    // flag to confirm we are getting orderitems
    const flagOrderItem = sessionStorage.getItem("GTMDTCflagOrderItem");
    const currentUser = localStorageUtil.get(CURRENT_USER);
    // condition to restrict setting the user details, login details & cart details while page reload,
    // allow if same page and the orderitems having value
    if (
      flag === null ||
      flag != window.location.href ||
      (flag === window.location.href && (!orderItems || orderItems.length > 0))
    ) {
      // allow if the orderitems value not setted in the state
      if (flagOrderItem === null) {
        if (mySite.enableGA) {
          if (currentUser !== null) {
            AsyncCall.sendUserDeatilsPageViewEvent(mySite.enableGA4, userLoggedIn, userDetails);
            //AsyncCall.sendLoginPageViewEvent(mySite.enableGA4, userLoggedIn, userDetails1);
          }
          if (!orderItems || orderItems.length > 0) {
            AsyncCall.sendLoginCartDetailsEvent({ cart, orderItems }, { enableGA4: mySite.enableGA4 });
            AsyncCall.sendCartDetailsPageViewEvent({ cart, orderItems }, { enableGA4: mySite.enableGA4 });
            // create a session value to notify orderitems received and updated in the state
            sessionStorage.setItem("GTMDTCflagOrderItem", window.location.href);
          }
          sessionStorage.setItem("GTMDTCflag", window.location.href);
        }
      }
    }
    // Clear the orderitems session flag when the job done and when navigate to new window
    if (flagOrderItem != null && flag != window.location.href) {
      sessionStorage.removeItem("GTMDTCflagOrderItem");
    }
    const cleanup = () => {
      sessionStorage.removeItem("GTMDTCflag");
    };
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  });

  useEffect(() => {
    const flag = sessionStorage.getItem("GTMDTCUserLoginflag");
    const currentURL = window.location.href;
    if (currentURL.includes("/account") && (flag === null || flag != window.location.href)) {
      if (
        mySite.enableGA &&
        userDetails !== null &&
        userDetails?.details != undefined &&
        userDetails?.details != null
      ) {
        AsyncCall.sendLoginPageViewEvent(mySite.enableGA4, userLoggedIn, userDetails);
        sessionStorage.setItem("GTMDTCUserLoginflag", window.location.href);
      }
    }
    if (currentURL.includes("/sign-in")) {
      sessionStorage.removeItem("GTMDTCUserLoginflag");
    }
  }, [userDetails]);

  useEffect(() => {
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);
      navigate(ROUTES.HOME);
      setaccountOverlay(false);
      AsyncCall.sendUserDeatilsPageViewEvent(mySite.enableGA4, userLoggedIn, null);
      AsyncCall.sendLoginPageViewEvent(mySite.enableGA4, userLoggedIn, null);
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payload,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          setTopCategories(res.data.contents);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION(res.data.contents));
        })
        .catch((e) => {
          setTopCategories([]);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, language, selectedSellers]);

  useEffect(() => {
    const payloadBase: any = {
      widget: widgetName,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    dispatch(SELLERS_GET_ACTION(payloadBase));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        //handleMiniCartClick();
      }
    }
  }, [success]);

  useEffect(() => {
    //splice to empty array
    cancels.splice(0, cancels.length).forEach((cancel) => {
      cancel();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellerConfig.initialized) {
      if (sellers?.length && !sellerConfig.showSellerList) {
        dispatch(SET_SELLER_ACTION({ sellers: null }));
      } else if (sellers?.length && !selectedSellers?.length) {
        dispatch(SET_SELLER_ACTION({ sellers }));
      }
    }
  }, [sellerConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotName,
        properties: {
          emsName: headerConfig.espot.eSpotName,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
  // overflow hidden
  const [hideOverflow, setHideOverflow] = useState(false);
  useEffect(() => {
    document.body.style.overflow = hideOverflow ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [hideOverflow]);

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
    setHideOverflow(!hideOverflow);
    // setHideOverflow(window.innerWidth <= 959);
  };
  const turnOffSearchBar = () => {
    setShowSearchBar(false);
    setHideOverflow(!hideOverflow);
  };
  const turnOnSearchBar = () => setShowSearchBar(true);
  const toggleOpen = () => setOpen(!open);
  const turnOffOpen = () => setOpen(false);
  const turnOnOpen = () => setOpen(true);
  const handleButtonClick = () => {
    setTimeout(() => {
      const element = document.getElementById("create-account-link");
      if (element) {
        element.click();
      } else {
        console.log("fff");
        setTimeout(() => {
          const element = document.getElementById("create-account-link");
          if (element) {
            element.click();
          }
        }, 800);
      }
    }, 200);
  };
  //search toggle
  const [toggle, setToggle] = useState(false);

  function openInNewTab(event, url) {
    event?.preventDefault();
    window.open(url);
  }

  return (
    <>
      <StyledHeader>
        {!reviewPage && !checkoutpayment && !checkoutShipping && (
          <div className="header-bg-new">
            <StyledContainer>
              <StyledGrid container justifyContent="space-between" alignItems="center" className="headerRowOne">
                <StyledGrid item className="headerLogo">
                  <StyledGrid className="header-topbarSection" container alignItems="center" spacing={2}>
                    {/* <StyledGrid item>
                  <Hidden mdUp>
                    <button className="menu-hamburger" data-testid="menu-hamburger-element" onClick={toggleOpen}>
                      <MenuIcon />
                    </button>
                  </Hidden>
                </StyledGrid> */}
                    {mySite != null && (
                      <StyledGrid item>
                        <div className="header-branding">
                          {/* <ContentRecommendationWidget {...crwProps} /> */}
                          <StyledLink to={ROUTES.HOME}>
                            <img
                              src="https://static.augustasportswear.com/static/AAlogo_H_white.svg"
                              alt="Augusta Active"
                            />
                          </StyledLink>
                        </div>
                      </StyledGrid>
                    )}
                  </StyledGrid>
                </StyledGrid>

                <StyledGrid item className="header-logo">
                  <StyledGrid className="header-topbarSection" container alignItems="center" direction="row">
                    <div className="external-site-link">
                      <StyledLink onClick={(event: any) => openInNewTab(event, "https://www.augustasportswear.com")}>
                        Wholesale site
                        <span className="material-icons-outlined">open_in_new</span>
                      </StyledLink>
                    </div>
                    {/* search desktop click */}
                    <Hidden smDown>
                      <StyledSearchBarButton
                        onClick={toggleSearchBar}
                        variant="text"
                        color="secondary"
                        className="header-actionsButton">
                        {/* <SearchIcon /> */}
                        <span className="material-icons-outlined">search</span>
                      </StyledSearchBarButton>

                      {showSearchBar ? (
                        <StyledGrid
                          item
                          data-testid="search-bar-desktop-largetablet-element"
                          className="search-desktop-container">
                          <div className="search-modal-close" onClick={turnOffSearchBar}>
                            <span className="material-icons-outlined">cancel</span>
                          </div>
                          <div className="search-toggle-box">
                            <SearchBar
                              showSearchBar={showSearchBar}
                              closeSearchBar={turnOffSearchBar}
                              openSearchBar={turnOnSearchBar}
                            />
                          </div>
                          <div className="search-overlay"></div>
                        </StyledGrid>
                      ) : null}
                    </Hidden>
                    {/* mobil menu icon */}
                    <Hidden mdUp>
                      {/* <button className="menu-hamburger" data-testid="menu-hamburger-element" onClick={toggleOpen}>

                    </button> */}
                      <div className="hamburger-menu" onClick={toggleOpen}>
                        <span className="material-icons-outlined">
                          <MenuIcon />
                        </span>
                        <span className="icon-text">Menu</span>
                      </div>
                    </Hidden>
                    <Hidden smDown mdUp>
                      <StyledGrid item>
                        <StoreLocatorButton />
                      </StyledGrid>
                    </Hidden>
                    {sellerConfig?.showSellerList ? <MarketplacePopper {...{ sellerConfig }} /> : null}
                    {loggedIn ? (
                      <StyledGrid item className="padding-5 AccountPopper">
                        <StyledButton
                          ref={myAccountElRef}
                          variant="text"
                          color="secondary"
                          className="header-actionsButton"
                          onClick={handleMyAccountClick}
                          testId="header-action-account">
                          {isTablet ? (
                            // <StyledHeaderActions>
                            //   <AccountBoxIcon />
                            //   <StyledTypography variant="body1" component="p">
                            //     {isMobile ? t("Header.Actions.Account") : t("Header.Actions.YourAccount")}
                            //   </StyledTypography>
                            // </StyledHeaderActions>
                            <div className="mobile-AccountPopper">
                              <StyledTypography variant="body1" className="sign2" component="p">
                                <span className="material-icons-outlined mobile">account_circle</span>
                              </StyledTypography>
                              <span className="icon-text">Account</span>
                            </div>
                          ) : (
                            // <StyledBox className="welcome-text" display="flex" flexDirection="column">
                            //   <StyledTypography variant="button" component="p" className="account-welcome">
                            //     {firstName
                            //       ? t("Header.Actions.WelcomeFirstname", {
                            //           firstName,
                            //         })
                            //       : t("Header.Actions.WelcomeNoFirstname", {
                            //           lastName,
                            //         })}
                            //   </StyledTypography>

                            //   <StyledBox display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                            //     <StyledTypography variant="body1" className="sign2" component="p">
                            //       <span className="material-icons-outlined">account_circle</span>
                            //     </StyledTypography>
                            //     {myAccountPopperOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            //   </StyledBox>
                            // </StyledBox>
                            <StyledTypography variant="body1" className="sign2" component="p">
                              <span className="material-icons-outlined ">account_circle</span>
                            </StyledTypography>
                          )}
                        </StyledButton>
                        <StyledAccountPopper
                          id={myAccountPopperId}
                          open={myAccountPopperOpen}
                          anchorEl={myAccountElRef.current}
                          onClose={handleMyAccountPopperClose}
                          placement={w <= 40 ? "bottom" : "bottom-end"}
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: false,
                              boundariesElement: "scrollParent",
                            },
                            hide: {
                              enabled: false,
                            },
                          }}
                          className="account-popper z-index-2 ">
                          <ClickAwayListener onClickAway={handleMyAccountPopperClose}>
                            <StyledPaper className="horizontal-padding-2 account-mini box-shadow">
                              <AccountPopperContent
                                handleClose={handleMyAccountPopperClose}
                                handleOrgChange={handleOrgChange}
                                handleContractChange={handleContractChange}
                                handleLogout={handleLogout}
                                isB2B={isB2B}
                                userName={{ firstName }}
                                accountoverlay={accountoverlay}
                              />
                            </StyledPaper>
                          </ClickAwayListener>

                          <span className="tooltip-arrow"></span>
                        </StyledAccountPopper>
                      </StyledGrid>
                    ) : (
                      <StyledGrid
                        item
                        className="padding-5 header-signin"
                        style={{ display: "flex", alignItems: "center" }}>
                        <StyledLink to={ROUTES.SIGNIN}>
                          <Buttonify testId="header-sign-in">
                            <>
                              {isMobile ? (
                                <>
                                  {/* <AccountBoxIcon /> */}
                                  <span className="material-icons-outlined">account_circle</span>
                                  <StyledTypography variant="body1" className="icon-text">
                                    {t("Header.Actions.SignIn")}
                                  </StyledTypography>
                                </>
                              ) : (
                                <StyledTypography variant="body1" className="sign2" component="p">
                                  {/* {t("Header.Actions.SignInRegister")} */}
                                  <span className="material-icons-outlined">account_circle</span>
                                </StyledTypography>
                              )}
                            </>
                          </Buttonify>
                        </StyledLink>
                      </StyledGrid>
                    )}
                    {isShoppingEnabled && (
                      <StyledGrid item className="padding-5 miniCart">
                        <MiniCart
                          id={miniCartPopperId}
                          open={miniCartPopperOpen}
                          //handleClick={handleMiniCartClick}
                          handleClick={handleViewCart}
                          handleClose={handleMiniCartPopperClose}
                          ref={miniCartElRef}
                        />
                        <Hidden mdUp>
                          <span className="icon-text">Cart</span>
                        </Hidden>
                      </StyledGrid>
                    )}
                    <Hidden mdUp smDown>
                      <StyledGrid item className="padding-5">
                        <LanguageToggle
                          id={languageTogglePopperId}
                          open={languageTogglePopperOpen}
                          handleClick={handleLanguageToggleClick}
                          handleClose={handleLanguageTogglePopperClose}
                          ref={languageToggleElRef}
                        />
                      </StyledGrid>
                    </Hidden>
                    <StyledGrid item>
                      {/* search mobile click */}
                      <Hidden mdUp>
                        <StyledGrid item data-testid="search-bar-mobile-element">
                          <StyledSearchBarButton
                            // onClick={toggleSearchBar}
                            onClick={(event) => {
                              toggleSearchBar();
                              toggleClass();
                            }}
                            //className={`header-actionsButton ${showSearchBar && "active"}`}
                            variant="text"
                            color="secondary">
                            {/* <SearchIcon /> */}
                            <span className="material-icons-outlined">search</span>
                          </StyledSearchBarButton>

                          <span className="icon-text">Search</span>
                        </StyledGrid>
                      </Hidden>
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>

              <Hidden smDown>
                <ExpandedMenu pages={topCategories} />
              </Hidden>
            </StyledContainer>
          </div>
        )}
        {(reviewPage || checkoutpayment || checkoutShipping) && (
          <Hidden smDown>
            <StyledContainer className="header-bg-new checkout-header">
              <StyledGrid
                className="header-topbarSection"
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}>
                {/* <StyledGrid item>
                  <Hidden mdUp>
                    <button className="menu-hamburger" data-testid="menu-hamburger-element" onClick={toggleOpen}>
                      <MenuIcon />
                    </button>
                  </Hidden>
                </StyledGrid> */}
                {mySite != null && (
                  <StyledGrid item>
                    <div className="header-branding">
                      {/* <ContentRecommendationWidget {...crwProps} /> */}
                      <StyledLink to={ROUTES.HOME}>
                        <img
                          src="https://static.augustasportswear.com/static/AAlogo_H_white.svg"
                          alt="Augusta Active"
                        />
                      </StyledLink>
                      <span className="secure-logo">
                        <span className="material-icons-outlined icon">https</span>
                        <span className="text-white ">Secure</span>
                      </span>
                    </div>
                  </StyledGrid>
                )}
              </StyledGrid>
            </StyledContainer>
          </Hidden>
        )}
        {showSearchBar && (
          <Hidden mdUp>
            <StyledContainer className="bottom-padding-1 search-desktop-container">
              <div
                className="search-modal-close"
                // onClick={turnOffSearchBar}
                onClick={() => {
                  turnOffSearchBar();
                  toggleCloseClass();
                }}>
                <span className="material-icons-outlined">cancel</span>
              </div>
              <div className="search-toggle-box">
                <SearchBar
                  showSearchBar={showSearchBar}
                  // closeSearchBar={turnOffSearchBar}
                  closeSearchBar={() => {
                    turnOffSearchBar();
                    toggleCloseClass();
                  }}
                  openSearchBar={turnOnSearchBar}
                />
              </div>
              <div className="search-overlay"></div>
            </StyledContainer>
          </Hidden>
        )}

        <StyledSwipeableDrawer
          anchor={useMediaQuery(theme.breakpoints.up("sm")) ? "top" : "right"}
          open={open}
          onClose={turnOffOpen}
          onOpen={turnOnOpen}
          className="header-menu"
          data-testid="header-menu-drawer-element">
          <StyledContainer>
            <StyledGrid container spacing={2} className={"menu-container " + (open ? "open" : "")}>
              <StyledGrid container justifyContent="center">
                <StyledGrid className="mobile-logo">
                  <img src="https://static.augustasportswear.com/static/AAlogo_V_black.svg" alt="Augusta"></img>
                </StyledGrid>
                <StyledGrid className="close-position mobile-right-17">
                  <div className="close-icon width-full text-right ">
                    <span className="material-icons-outlined" onClick={turnOffOpen}>
                      close
                    </span>
                  </div>
                </StyledGrid>
              </StyledGrid>
              <MegaMenu menutitle={t("MegaMenu.Title")} pages={topCategories} closeMegaMenu={turnOffOpen}></MegaMenu>
              {!loggedIn ? (
                <StyledGrid container justifyContent="space-around" className="margin-top-20">
                  <StyledGrid>
                    <StyledLink to={ROUTES.SIGNIN}>
                      <StyledButton testId="sign-in" color="primary" size="large" onClick={turnOffOpen}>
                        sign in
                      </StyledButton>
                    </StyledLink>
                  </StyledGrid>
                  <StyledGrid>
                    <StyledLink to={ROUTES.SIGNIN}>
                      <StyledButton
                        testId="sign-in"
                        color="secondary"
                        size="large"
                        onClick={() => {
                          turnOffOpen();
                          handleButtonClick();
                        }}>
                        sign up
                      </StyledButton>
                    </StyledLink>
                  </StyledGrid>
                </StyledGrid>
              ) : null}

              {loggedIn ? (
                <StyledGrid container direction="column" className="margin-top-20">
                  <StyledLink to={ROUTES.ORDER_HISTORY} className="margin-4" onClick={turnOffOpen}>
                    <StyledTypography tabIndex="0" variant="h6" className="vertical-margin-4">
                      My Orders
                    </StyledTypography>
                  </StyledLink>
                  {/* <StyledLink to={ROUTES.WISH_LIST} className="margin-4" onClick={turnOffOpen}>
                    <StyledTypography tabIndex="0" variant="h6" className="vertical-margin-4">
                      My Wishlist
                    </StyledTypography>
                  </StyledLink> */}
                  <StyledLink to={ROUTES.ACCOUNT} className="margin-4" onClick={turnOffOpen}>
                    <StyledTypography tabIndex="0" variant="h6" className="vertical-margin-4">
                      My Profile
                    </StyledTypography>
                  </StyledLink>
                </StyledGrid>
              ) : null}
              <StyledGrid container>
                <div className="external-site-link-mob">
                  <StyledLink onClick={(event: any) => openInNewTab(event, "https://www.augustasportswear.com")}>
                    <StyledTypography tabIndex="0" variant="h6" className="vertical-margin-4 padding-right-5">
                      visit our Wholesale site
                    </StyledTypography>
                    <span className="material-icons-outlined">open_in_new</span>
                  </StyledLink>
                </div>
              </StyledGrid>
            </StyledGrid>
          </StyledContainer>
        </StyledSwipeableDrawer>
        {showaccountOverlay && <div className="overlay"></div>}
      </StyledHeader>
    </>
  );
};

export { Header };
