/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styles from "./../styles.module.scss";
export const palette = {
  asb: {
    main: styles.mainColor,
    light: styles.colorGrey,
    dark: "",
  },
  emerald: {
    main: styles.mainColor,
    light: styles.colorGrey,
    dark: "",
  },
  sapphire: {
    main: "#0091FF",
    light: "#54B5FF",
    dark: "#016FC2",
  },
  text: {
    primary: styles.mainColor,
    secondary: styles.secondaryColor,
    disabled: "#e7e7ea",
    highlight: "#f44336",
    alert: "#C10C0D",
    expandedMenu: "#FFFFFF",
  },
  border: {
    main: styles.colorBorder,
    hover: "#F0F4F7",
    alert: "#C10C0D",
  },
  divider: {
    dark: "#4C5256",
  },
  background: {
    default: "styles.colorWhite",
    paper: styles.colorWhite,
    transparency: "CC",
  },
  disabled: {
    background: "#F0F4F7",
  },
};
