/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Custom libraries
import { REG_EX } from "../constants/common";
import addressValidationService from "../_foundation/apis/asg/address-validation.service";

const addressUtil = {
  optionalAddressFields: ["addressLine2", "nickName"],
  addressFormFields: [
    "firstName",
    "lastName",
    "addressLine1",
    "addressLine2",
    "city",
    "country",
    "state",
    "zipCode",
    "phone1",
    "nickName",
    "email1",
    "addressType",
  ],

  validateAddressForm: (formData: any, edit?: boolean) => {
    if (formData.nickName === "") {
      formData.nickName = addressUtil.getTimeStamp();
    }

    const editVal = edit ? edit : false;

    for (const key in formData) {
      if (!addressUtil.optionalAddressFields.includes(key)) {
        if (formData[key] !== undefined && formData[key].trim() === "") {
          return false;
        }
      }
    }

    if (!addressUtil.validatePhoneNumber(formData.phone1)) {
      return false;
    }
    if (!addressUtil.validateEmail(formData.email1)) {
      return false;
    }

    if (!editVal && !addressUtil.validateNickName(formData.nickName)) {
      return false;
    }

    const payload = {
      addressLine: formData.addressLine1 + (formData.addressLine2 ? `, ${formData.addressLine2}` : ""),
      city: formData.city,
      state: formData.state,
      country: "US",
      zipCode: formData.zipCode,
      nickName: formData.nickName,
      xaddr_addressField1: "Residential",
    };

    // if (!addressUtil.invokeUPS(payload)) {
    //     return false;
    // }
    return true;
  },

  getTimeStamp: () => {
    let timeStampInMs: any;
    if (window.performance && window.performance.now && window.performance.timeOrigin) {
      timeStampInMs = window.performance.now() + window.performance.timeOrigin;
    } else {
      timeStampInMs = Date.now();
    }
    return timeStampInMs.toString();
  },

  validatePhoneNumber: (phoneNumber: string) => {
    const PHONE = REG_EX.PHONE;
    return phoneNumber === undefined || phoneNumber.trim() === "" || PHONE.test(phoneNumber.trim());
  },

  validateEmail: (email: string) => {
    const EMAIL = REG_EX.EMAIL;
    return email === undefined || email.trim() === "" || EMAIL.test(email);
  },

  validateNickName: (nickName: string) => {
    const NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR = REG_EX.NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR;
    // return nickName === undefined || nickName.trim() === "" || NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR.test(nickName);
    return true;
  },

  invokeUPS: (payload: any) => {
    try {
      addressValidationService.AddressValidateUPS(payload).then((res: any) => {
        console.log("UPS Response");
        console.log(res.data);
        if (res.data !== undefined && res.data !== "") {
          const response = res.data;
          if (response.success == "true") {
            return true;
          } else {
            if (response.noCandidates == "true") {
              const notAbleToValidate =
                "We were unable to validate the address you entered. " +
                "Please verify that the information below is correct.";
              return false;
            } else {
              const hasSuggAddr =
                "During the address validation process, the following address recommendations were returned. " +
                "Please select the suggested address or provide correct address to continue.";

              //DISPLAY SUGGESTED ADDRESS
              const sugAdr = response.AddressSuggestion[0];
              let addrF1 = "";
              let orF1 = "";
              if (sugAdr.addressField1 == "COMDL") {
                addrF1 = "Commercial";
              } else {
                addrF1 = "Residential";
              }
              if (response.addressField1 == "COMDL") {
                orF1 = "Commercial";
              } else {
                orF1 = "Residential";
              }
              let addressLine = sugAdr.address1;
              if (sugAdr.address2 != null && sugAdr.address2.trim()) {
                addressLine = sugAdr.address1 + ", " + sugAdr.address2;
              }
              let origAddressLine = response.address1;
              if (response.address2 != undefined && response.address2 != null && response.address2 != "") {
                origAddressLine = response.address1 + ", " + response.address2;
              }
              const suggestedAddr =
                "&nbsp;<b>Suggested Address: </b><span class='street bloc'>" +
                addressLine +
                ",</span> <span class='city'>" +
                sugAdr.city +
                ",</span> <span class='country'>" +
                sugAdr.country +
                ",</span> <span class='state'>" +
                sugAdr.state +
                ",</span> <span class='zip'>" +
                sugAdr.zipCode +
                ",</span><br> <span class='type'>Type:</span><span class='res'>" +
                addrF1 +
                "</span>";
              const originalAddr =
                "&nbsp;<b>Use Original: </b><span class='street bloc'>" +
                origAddressLine +
                ",</span> <span class='city'>" +
                response.city +
                ",</span> <span class='country'>" +
                response.country +
                ",</span> <span class='state'>" +
                response.state +
                ",</span> <span class='zip'>" +
                response.zipCode +
                ",</span><br> <span class='type'>Type:</span><span class='res'>" +
                orF1 +
                "</span>";
              console.log("suggestedAddr");
              console.log(suggestedAddr);
              console.log("originalAddr");
              console.log(originalAddr);
              return false;
            }
          }
        }
      });
    } catch (error) {
      console.log("Error fetching response from UPSValidation" + error);
      return true;
    }
  },

  removeLeadingTrailingWhiteSpace: (formData: any): any => {
    const result: any = {};
    // remove leading and trailing white space from all form input fields.
    if (formData !== undefined && formData !== null) {
      Object.keys(formData).forEach((key) => {
        const value = formData[key].trim();
        result[key] = value;
      });
    }
    return result;
  },

  removeIgnorableAddressFormFields: (formData: any): any => {
    const result: any = { ...formData };
    for (const key in result) {
      if (!addressUtil.addressFormFields.includes(key)) {
        delete result[key];
      }
    }
    return result;
  },

  getRegisteredInitialAddress: (address): any => {
    const keys = [
      "addressId",
      "addressLine",
      "addressType",
      "city",
      "country",
      "email1",
      "firstName",
      "lastName",
      "nickName",
      "phone1",
      "primary",
      "state",
      "zipCode",
    ].filter((k) => address[k] != null); // != is intentional (instead of !== since != [or ==] will equate null and undefined)
    const rc = Object.assign({}, ...keys.map((k) => ({ [k]: address[k] })));
    return rc;
  },
};

export default addressUtil;
