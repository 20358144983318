/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

// Standard libraries
import React, { useMemo, useState, useEffect, useContext } from "react";
import getDisplayName from "react-display-name";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@material-ui/core";
import {
  ProductAttributes,
  StyledTypography,
  QuickInfoContext,
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  ProductQuantity,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";
import HTMLReactParser from "html-react-parser";
import { ProductProvider } from "../../_foundation/context/product-context";
import ProdDetail from "../commerce-widgets/product-details-widget";
import { WidgetProps } from "../../_foundation/constants/seo-config";
import MerchantAssociation from "../commerce-widgets/merchandising-association-widget";

interface QuickInfoProps {
  open: boolean;
}

const QuickViewModal: React.FC = (props) => {
  const { t } = useTranslation();
  const { data, dispatch } = useContext(QuickInfoContext);

  const product = data && data.quickInfoProduct ? data.quickInfoProduct.product : {};

  const product_number = product["product_number"] ? product["product_number"] : product["partNumber"];
  const [heightClasss, setheightClasss] = useState(0);
  const QuickViewClose = (e) => {
    e.preventDefault();
    dispatch({ type: "UPDATE", payload: { product: product, open: false } });
  };
  useEffect(() => {
    const quickviewHeight = () => {
      setTimeout(() => {
        const divHeights = document.getElementById("pdp-rating-top")?.offsetHeight;
        console.log(divHeights, "offsetHeight");
        if (divHeights) {
          setheightClasss(divHeights);
        }
      }, 2000);
    };
    quickviewHeight();
  }, []);
  const widgetProps: WidgetProps = {
    widget: {
      id: "plp-quick-info-widget",
      widgetName: "plp-quick-info-widget",
      name: "plp-quick-info-widget",
      properties: {},
    },
    page: { externalContext: { identifier: product_number, fromPage: "plp-quickinfo" } },
  };

  const merchandsingProps: WidgetProps = {
    widget: {
      id: "plp-quick-info-merchandising-widget",
      widgetName: "plp-quick-info-merchandising-widget",
      name: "plp-quick-info-merchandising-widget",
      properties: { requestPage: "QuickView", type: "COMPARABLE" },
    },
    page: { type: "plp-quickinfo" },
  };
  return (
    <>
      {data && data.quickInfoProduct && (
        <div className="quick-cart">
          <StyledDialog
            className="quick-view-modal"
            open={data.quickInfoProduct.open}
            onClose={QuickViewClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="quick-view-dialog">
            <StyledDialogTitle testId="quick-close" onClick={QuickViewClose} />
            <StyledDialogContent className="quick-view-content">
              <ProductProvider>
                <ProdDetail {...widgetProps} />
                {/* <MerchantAssociation {...merchandsingProps}/> */}
              </ProductProvider>
            </StyledDialogContent>
          </StyledDialog>
        </div>
      )}
    </>
  );
};

export { QuickViewModal };
