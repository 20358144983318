import axios, { AxiosRequestConfig, Method } from "axios";
import { getSite } from "../../hooks/useSite";

const addressValidationService = {
  AddressValidateUPS(params: any): Promise<any> {
    const method: Method = "POST";
    const header = new Headers();
    header.append("Accept", "*/*");
    header.append("Content-Type", "application/json");
    header.append("Access-Control-Allow-Origin", "http://local.augustaactive.com");
    const baseUrl = window.location.origin;
    const storeID = getSite()?.storeID;

    const url = `${baseUrl}/wcs/resources/store/${storeID}/person/AddressValidation/@self`;

    const response: AxiosRequestConfig = {
      headers: header,
      method: method,
      data: params,
      url: url,
    };
    return axios(response);
  },
};

export default addressValidationService;
