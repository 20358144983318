/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/products.service";
import customService from "../../../_foundation/apis/asg/custom.service";
import searchSpringHeaderService from "../../../utils/searchSpringHeaderSetup";
import Cookies from "universal-cookie";

//Redux
import * as ACTIONS from "../../action-types/catalog";
import { getSite } from "../../../_foundation/hooks/useSite";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;
    const payloadParams = payload.parameters;

    searchSpringHeaderService.getSearchSpringSessionInfo();
    const cookies = new Cookies();
    const searchSpringFacetParams = getSearchSpringFacetParams(payload);
    let ss_categoryHierarchy = "";
    if (payloadParams.categoryId !== undefined && payloadParams.categoryId !== "")
      ss_categoryHierarchy = yield call(fetchCategoryHierarchy, payloadParams);

    let ssCategory = "";
    let ssSearchTerm = "";
    let tagTerm = "";
    let payloadStates: any;
    let payloadQuery: any;
    let selectedSortOption = "";
    let ssResultsPerPage: number = 0;

    if (payload.states !== undefined && payload.states !== "") {
      payloadStates = payload.states;
    }

    if (payloadParams.query !== undefined && payloadParams.query !== "") {
      payloadQuery = payloadParams.query;
    }

    if (payloadStates !== undefined) {
      if (payloadStates.selectedSortOption !== undefined && payloadStates.selectedSortOption !== "") {
        selectedSortOption = payloadStates.selectedSortOption;
      }
    }

    if (selectedSortOption == "" && payloadQuery !== undefined) {
      if (payloadQuery.orderBy !== undefined && payloadQuery.orderBy !== "") {
        selectedSortOption = payloadQuery.orderBy;
      }
    }

    if (payloadParams.offset !== undefined && payloadParams.offset == 0) {
      ssResultsPerPage = payloadParams.limit;
    } else if (payloadStates !== undefined && payloadQuery !== undefined) {
      if (payloadStates.selectedPageOffset !== undefined && payloadStates.selectedPageOffset !== "") {
        ssResultsPerPage = payloadStates.selectedPageOffset + payloadQuery.limit;
      }
    }

    if (payloadParams.searchTerm !== undefined && payloadParams.searchTerm !== "") {
      ssSearchTerm = payloadParams.searchTerm;
    } else if (payloadQuery !== undefined) {
      if (payloadQuery.searchTerm !== undefined && payloadQuery.searchTerm !== "") {
        ssSearchTerm = payloadQuery.searchTerm;
      }
    }

    if (payloadParams.tagTerm !== undefined && payloadParams.tagTerm !== "") {
      tagTerm = payloadParams.tagTerm;
    } else if (payloadQuery !== undefined) {
      if (payloadQuery.tagTerm !== undefined && payloadQuery.tagTerm !== "") {
        tagTerm = payloadQuery.tagTerm;
      }
    }

    if (ss_categoryHierarchy !== "") {
      ssCategory = ss_categoryHierarchy.toUpperCase();
    } else if (payloadParams.categoryName !== "" && payloadParams.categoryName !== undefined) {
      ssCategory = payloadParams.categoryName.toUpperCase();
    }

    //const response = yield call(productsService.findProductsUsingGET, payload.parameters);

    //Search Spring api URL & siteId info
    let URL = "";
    const siteInfo = getSite();
    URL = siteInfo?.storeCfg?.userData?.SearchSpringPLPUrl;
    const siteId = siteInfo?.storeCfg?.userData?.SearchSpringSiteId;

    const params = {
      resultsFormat: "native",
      resultsPerPage: ssResultsPerPage,
      ss_category: encodeURIComponent(ssCategory),
      searchTerm: ssSearchTerm,
      tagTerm: tagTerm,
      ssFacetParams: searchSpringFacetParams,
      selectedSortOption: selectedSortOption,
    };
    const headers = {
      "user-agent": "Searchspring-App",
      "searchspring-session-id": cookies.get("ssSessionIdNamespace"),
      "searchspring-user-id": cookies.get("ssUserId"),
    };

    let plpURL = URL;
    plpURL = plpURL.concat("siteId=" + siteId);
    if (params.searchTerm !== "") plpURL = plpURL.concat("&q=" + params.searchTerm);
    else if (params.tagTerm !== "") plpURL = plpURL.concat("&tag=" + params.tagTerm);
    else if (params.ss_category !== "") plpURL = plpURL.concat("&bgfilter.ss_category=" + params.ss_category);
    if (params.ssFacetParams !== "") plpURL = plpURL.concat("&" + params.ssFacetParams);
    if (params.resultsFormat !== "") plpURL = plpURL.concat("&resultsFormat=" + params.resultsFormat);
    if (params.resultsPerPage >= 0) plpURL = plpURL.concat("&resultsPerPage=" + params.resultsPerPage);
    if (params.selectedSortOption !== "") plpURL = plpURL.concat("&sort." + params.selectedSortOption);

    payloadParams["searchSpringApiUrl"] = plpURL;
    payloadParams["headers"] = headers;

    const response = yield call(customService.getSearchSpringResultsPLP, payloadParams);

    yield put({
      type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* fetchProductListDetails(action: any) {
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}
/**
 * Function to set facet params for SearchSpring API
 */
function getSearchSpringFacetParams(payload) {
  let selectedFacetState: any;
  let ssFacetParams = "";
  if (payload.states !== undefined && payload.states !== "") {
    const payloadStates = payload.states;
    if (payloadStates.selectedFacets !== undefined && payloadStates.selectedFacets !== "") {
      selectedFacetState = payloadStates.selectedFacets;
    }
  }

  if (selectedFacetState !== undefined && selectedFacetState !== "") {
    if (Object.keys(selectedFacetState).length > 0) {
      Object.entries(selectedFacetState).map((key) => {
        let facetName: any;
        facetName = key[1];
        facetName = facetName.toString().toLowerCase();
        if (facetName.indexOf("category") !== -1) {
          facetName = "itemtype";
        } else if (facetName.indexOf("gender") !== -1) {
          facetName = "asgswatchgender";
        } else if (facetName.indexOf("color") !== -1) {
          facetName = "asgswatchcolorfamily";
        } else if (facetName.indexOf("size") !== -1) {
          facetName = "available_sizes";
        } else if (facetName.indexOf("price") !== -1) {
          facetName = "msrp";
        } else if (facetName.indexOf("type") !== -1) {
          facetName = "product_category";
        } else if (facetName.indexOf("closure") !== -1) {
          facetName = "asgheadwearclosure";
        } else if (facetName.indexOf("visor") !== -1) {
          facetName = "asgheadwearvisor";
        } else if (facetName.indexOf("crown") !== -1) {
          facetName = "asgheadwearcrown";
        } else if (facetName.indexOf("ribbon") !== -1) {
          facetName = "asgswatchribbon";
        } else if (facetName.indexOf("item status") !== -1) {
          facetName = "asgswatchribbon";
        }

        const facetValue = key[0];
        if (facetName == "msrp") {
          if (ssFacetParams === "") ssFacetParams = ssFacetParams.concat("filter.").concat(facetValue);
          else ssFacetParams = ssFacetParams.concat("&filter.").concat(facetValue);
        } else {
          if (ssFacetParams === "")
            ssFacetParams = ssFacetParams.concat("filter.").concat(facetName).concat("=").concat(facetValue);
          else ssFacetParams = ssFacetParams.concat("&filter.").concat(facetName).concat("=").concat(facetValue);
        }
      });
    }
  }

  return ssFacetParams;
}

/**
 * Function to get Category Hierarchy for SearchSpring API
 */
export function* fetchCategoryHierarchy(payload: any) {
  try {
    const categoryHierarchyPayload: [] = [];
    categoryHierarchyPayload["categoryId"] = payload.categoryId;
    const categoryHierachyHeaders = {
      responseFormat: "json",
      "Access-Control-Allow-Origin": "*",
    };
    categoryHierarchyPayload["headers"] = categoryHierachyHeaders;

    let categoryHierarchyArray: any[] = [];
    let categoryHierarchy = "";
    yield customService.getCategoryHierarchy(categoryHierarchyPayload).then((res: any) => {
      if (res.data !== undefined && res.data !== "") {
        const response = res.data;
        categoryHierarchyArray = response["breadCrumbTrailEntryView"];
        if (categoryHierarchyArray?.length > 0) {
          categoryHierarchyArray.map((f) => {
            if (categoryHierarchy === "") {
              categoryHierarchy = categoryHierarchy.concat(f.label);
            } else {
              categoryHierarchy = categoryHierarchy.concat(">").concat(f.label);
            }
          });
        }
      }
    });
    return categoryHierarchy;
  } catch (error) {
    console.log("Error fetching Category Hierarchy" + error);
  }
}
