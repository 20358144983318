/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
import Cookies from "universal-cookie";
import cloneDeep from "lodash/cloneDeep";
//Foundation libraries
import { useSite, getSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
import customService from "../../../_foundation/apis/asg/custom.service";
import * as colorJson from "../../../assets/colorMapping.json";
import searchSpringHeaderService from "../../../utils/searchSpringHeaderSetup";
//Custom libraries
import { CommerceEnvironment, KEY_CODES, SEARCHTERM } from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";

//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
import { resetProductListAction } from "../../../redux/actions/catalog";
import { keywordSelector } from "../../../redux/selectors/search";
//UI
import {
  StyledTextField,
  StyledIconButton,
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
  StyledLink,
  StyledTypography,
  StyledGrid,
  StyledButton,
} from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import FormattedPriceDisplay from "../formatted-price-display";

interface Product {
  name: string;
  brand: string;
  thumbnailImageUrl: string;
  msrp_usd: string;
  price: string;
  url: string;
  styleId: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ showSearchBar, openSearchBar, closeSearchBar }) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<Array<any>>([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<Array<any>>([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<any>>([]);
  const [sellerSuggestions, setSellerSuggestions] = React.useState<Array<any>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location: any = useLocation();

  const searchField = t("SearchBar.SearchField");
  const keywordTitle = t("SearchBar.KeywordTitle");
  const categoryTitle = t("SearchBar.CategoryTitle");
  const brandTitle = t("SearchBar.BrandTitle");
  const sellerTitle = t("SearchBar.SellerTitle");
  const [input, setInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);
  const [showSellers, setShowSellers] = React.useState(false);
  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [sellers, setSellers] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(() => new Map());

  const [inputDisabled, setinputDisabled] = React.useState(true);
  const [sugProducts, setSugProducts] = React.useState<Product[]>([]);
  const [firstThreeProducts, setFirstThreeProducts] = React.useState<Product[]>([]);
  const autoSuggestTimerRef = React.useRef<number | NodeJS.Timeout>(-1);
  const autoSuggestKeystrokeDelay = 400;

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setSellerSuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowSellers(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setShowSearchBar(!showSearchBar);
    dispatch(resetProductListAction());
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
    setInput("");
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (mySite && contractId) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand", "Seller"],

        contractId: contractId,
        catalogId: catalogId,
        ...payloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {
            const categoriesResponse = res?.data?.suggestionView[0]?.entry;
            const brandsResponse = res?.data?.suggestionView[1]?.entry;
            const sellersResponse = res?.data?.suggestionView[2]?.entry;
            if (categoriesResponse) {
              generateCategoriesList(categoriesResponse);
              generateCategoriesURL(categoriesResponse);
            }
            if (brandsResponse) {
              generateBrandsList(brandsResponse);
            }
            if (sellersResponse) {
              generateSellersList(sellersResponse);
            }
            setinputDisabled(false);
          }
        })
        .catch((e) => {
          console.warn("fail to get category, brand and seller suggestions.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists = categoriesResponse.map((i) => i.fullPath);
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists = brandsResponse.map((i) => i.name);
    setBrands(lists);
  };
  const generateSellersList = (sellersResponse: any[]) => {
    const lists = sellersResponse.map((i) => i.name);
    setSellers(lists);
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      const url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const doDynamicAutoSuggest = (searchTerm) => {
    if (typeof autoSuggestTimerRef.current === "number" && autoSuggestTimerRef.current !== -1) {
      clearTimeout(autoSuggestTimerRef.current);
      autoSuggestTimerRef.current = -1;
    }
    autoSuggestTimerRef.current = setTimeout(() => {
      retrieveSearchSpringSuggestions(searchTerm);
      autoSuggestTimerRef.current = -1;
    }, autoSuggestKeystrokeDelay);
  };
  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;

    setInput(element.value);
    // retrieveSuggestions(element.value);
    doDynamicAutoSuggest(element.value);
  };

  const retrieveSearchSpringSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const siteInfo = getSite();
        const siteId = siteInfo?.storeCfg?.userData?.SearchSpringSiteId;
        let URL = "";
        URL = siteInfo?.storeCfg?.userData?.SearchSpringSearchUrl;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          url: URL,
          siteId: siteId,
          ...payloadBase,
        };

        customService.getSearchSpringAutoSuggestionKeywords(parameters).then((res) => {
          if (res.data !== undefined && res.data !== "") {
            const response = res?.data ?? "";
            let terms: any[] = [];
            let exactMatch: any[] = [];
            let products: any[] = [];
            terms = response?.alternatives ?? "";
            exactMatch = response?.suggested ?? "";
            products = response?.products ?? "";
            console.log(exactMatch);

            if (terms.length > 0 || Object.keys(exactMatch).length > 0) {
              generateSearchSpringSuggestionList(terms, exactMatch, searchTerm, products);
            }
          }
        });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };
  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          ...payloadBase,
        };

        siteContentService.findKeywordSuggestionsByTermUsingGET(parameters).then((res) => {
          if (res.status === OK) {
            const keywordSuggestions = res?.data.suggestionView[0].entry;
            if (keywordSuggestions) {
              const list: string[] = [];
              generateSuggestionList(keywordSuggestions, searchTerm, list);
              generateCatgoriesBrandAndSellersSuggestions(searchTerm, list);
              setNameList(list);
            }
          }
        });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };

  const generateCatgoriesBrandAndSellersSuggestions = (userInput: string, listTemp: string[]) => {
    const regex = new RegExp(userInput, "ig");
    const matchedCategories = categories?.filter((e) => e.match(regex)).slice(0, 4);
    const lists = matchedCategories.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = "";
      suggestionSkeleton.name = suggestion;
      suggestionSkeleton.url = categoriesUrl.get(suggestion);
      nameListIndex++;
      listTemp.push(suggestion);
      return suggestionSkeleton;
    });
    setCategorySuggestions(lists);
    setShowCategories(true);

    const matchedBrands = brands?.filter((e) => e.match(regex)).slice(0, 4);
    const lists2 = matchedBrands.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = "";
      suggestionSkeleton.name = suggestion;
      suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
      nameListIndex++;
      listTemp.push(suggestion);
      return suggestionSkeleton;
    });
    setBrandSuggestions(lists2);
    setShowBrands(true);

    if (sellers.length > 0) {
      const matchedSellers = sellers?.filter((e) => e.match(regex)).slice(0, 4);
      const lists3 = matchedSellers.map((suggestion) => {
        const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
        suggestionSkeleton.arrIndex = nameListIndex.toString();
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
        nameListIndex++;
        listTemp.push(suggestion);
        return suggestionSkeleton;
      });
      setSellerSuggestions(lists3);
      setShowSellers(true);
    }
  };

  const generateSuggestionList = (keywordSuggestions: any[], userInput: string, listTemp: string[]) => {
    listTemp.push(userInput);
    const listTemp2: string[] = [];

    const lists = keywordSuggestions.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = "";
      suggestionSkeleton.name = suggestion.term;
      suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion.term;
      listTemp.push(suggestion.term);
      listTemp2.push(suggestion.term);
      nameListIndex++;
      return suggestionSkeleton;
    });
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);
  };

  const generateSearchSpringSuggestionList = (
    keywordSuggestions: any[],
    exactMatch: any[],
    searchTerm: string,
    products: any[]
  ) => {
    const listTemp: string[] = [];
    listTemp.push(searchTerm);
    const listTemp2: string[] = [];
    if (Object.keys(exactMatch).length > 0) {
      keywordSuggestions.unshift(exactMatch);
    }
    setKeywordSuggestions([]);
    const lists = keywordSuggestions.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = "";
      suggestionSkeleton.name = suggestion.text.charAt(0).toUpperCase() + suggestion.text.slice(1);
      suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion.text;
      listTemp.push(suggestion.text);
      listTemp2.push(suggestion.text);
      nameListIndex++;
      return suggestionSkeleton;
    });
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);
    let autoCompleteKeyword: string = "";
    if (Object.keys(exactMatch).length > 0) {
      autoCompleteKeyword = exactMatch["text"];
    } else {
      autoCompleteKeyword = searchTerm;
    }
    retrieveSearchSpringSuggestionProducts(autoCompleteKeyword, products);
  };

  const retrieveSearchSpringSuggestionProducts = (query: string, products: any) => {
    setFirstThreeProducts([]);
    setSugProducts([]);
    searchSpringHeaderService.getSearchSpringSessionInfo();
    const cookies = new Cookies();
    const payload: any[] = [];
    const headers = {
      "searchspring-session-id": cookies.get("ssSessionIdNamespace"),
      "searchspring-user-id": cookies.get("ssUserId"),
    };

    const siteInfo = getSite();
    const siteId = siteInfo?.storeCfg?.userData?.SearchSpringSiteId;
    let URL = "";
    URL = siteInfo?.storeCfg?.userData?.SearchSpringAutoCompleteUrl;

    const params = {
      siteId: siteId,
      q: query,
      resultsPerPage: "5",
      resultsFormat: "native",
      "filter.is_search_enabled": "true",
    };
    payload["headers"] = headers;
    payload["data"] = params;
    payload["url"] = URL;
    let suggestedPrdctsCount = 0;
    let productResponse: any;

    customService.getSearchSpringSuggestionProducts(payload).then((res) => {
      if (res.data !== undefined && res.data !== "") {
        if (res.data.results?.length > 0) {
          suggestedPrdctsCount = res.data?.results?.length;
          productResponse = res.data.results ?? "";
        } else {
          suggestedPrdctsCount = products.length;
          productResponse = products;
        }
        if (suggestedPrdctsCount > 0) {
          const transformedArray = productResponse.map((item) => ({
            name: item.name,
            brand: item.brand,
            thumbnailImageUrl: getThumbnailImageUrl(item, query),
            msrp_usd: item.msrp_usd,
            price: item.price,
            url: item.url,
            styleId: item.product_number,
          }));
          setSugProducts(transformedArray);
          const slicedProducts: Product[] = transformedArray.slice(0, 3);
          setFirstThreeProducts(slicedProducts);
        }
        //else{

        // }
      } else {
        console.log("Error getting the suggestion product list");
      }
    });
  };

  const getThumbnailImageUrl = (item: any, query) => {
    const searchSpringColors: any = item.color_images ? item.color_images : "";
    let image = item.thumbnailImageUrl;
    if (searchSpringColors !== undefined && searchSpringColors !== "") {
      const ssColors = JSON.parse(searchSpringColors.replace(/&quot;/g, '"'));
      let colorname: any;

      if (query !== undefined) {
        Object.entries(colorJson["default"]).map(([key, value]) => {
          if (query !== undefined && query !== "") {
            if (query.toLowerCase().indexOf(key.toLowerCase()) > -1) {
              colorname = value;
            }
          }
        });
      }
      Object.entries(ssColors).map(([key, color]: any) => {
        if (colorname !== "" && colorname !== undefined && color.colorFamily !== undefined) {
          if (color.colorFamily.toLowerCase().indexOf(colorname.toLowerCase()) > -1) {
            image = color.image;
          }
        }
      });
    }
    return image;
  };
  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    const len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            dispatch(resetProductListAction());
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
          redirectTo(url);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("search-bar-field")?.focus();
    }, 1400);
  }, []);

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar className="header-search-bar top-margin-2">
        <form onSubmit={submitSearch} noValidate>
          <span className="material-icons-outlined search-icon">search</span>
          <StyledTextField
            margin="normal"
            size="small"
            autoFocus
            id="search-bar-field"
            autoComplete="off"
            type="text"
            disabled={inputDisabled}
            placeholder={searchField}
            value={input}
            name="searchTerm"
            onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
            onKeyDown={onKeyDown}
            className="search-input"
            InputProps={{
              endAdornment: (
                <>
                  {showKeywords || showCategories || showBrands || showSellers ? (
                    <InputAdornment position="end" className="input-close">
                      <StyledIconButton
                        data-testid="button-clear-search-suggestions-input-fields"
                        onClick={clearSuggestionsAndInputField}>
                        <CloseIcon titleAccess={t("SearchBar.Clear")} />
                      </StyledIconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="start">
                      {/* <SearchIcon data-testid="icon-toggle-search-bar" onClick={toggleSearchBar} /> */}
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </form>

        {showKeywords || showCategories || showBrands || showSellers ? (
          <ClickAwayListener onClickAway={clearSuggestionsAndInputField}>
            <StyledGrid container className="result-list" md={6} xs={10} xl={6}>
              <StyledGrid className="searchBar-results" xs={12} md={4}>
                <ul>
                  {showKeywords ? (
                    <>
                      <StyledMenuTypography variant="subtitle2" className="searchBar-resultsCategory">
                        {keywordTitle}
                      </StyledMenuTypography>
                      {keywordSuggestions?.map((e: any, i: number) => (
                        <StyledLink
                          key={`keyword-${i}`}
                          testId={`keywords-${e.name}`}
                          to={e.url}
                          onClick={() => clearSuggestionsAndUpdateInputField(e.name)}>
                          <StyledMenuItem>
                            <StyledMenuTypography
                              className={e.arrIndex === index ? "active" : ""}
                              key={e.arrIndex}
                              id={`megamenu_department_${e.id}`}
                              title={e.name}>
                              {e.name}
                            </StyledMenuTypography>
                          </StyledMenuItem>
                        </StyledLink>
                      ))}
                    </>
                  ) : null}

                  {showCategories ? (
                    <>
                      <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                        {categoryTitle}
                      </StyledMenuTypography>
                      {categorySuggestions?.map((e: any, i: number) => (
                        <StyledLink
                          key={`category-${i}`}
                          testId={`category-${e.url.split("/").filter(Boolean).join("-")}`}
                          to={e.url}
                          onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                          <StyledMenuItem>
                            <StyledMenuTypography
                              className={e.arrIndex === index ? "active" : ""}
                              key={e.arrIndex}
                              id={`megamenu_department_${e.id}`}
                              title={e.name}>
                              {e.name}
                            </StyledMenuTypography>
                          </StyledMenuItem>
                        </StyledLink>
                      ))}
                    </>
                  ) : null}

                  {showBrands ? (
                    <>
                      <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                        {brandTitle}
                      </StyledMenuTypography>
                      {brandSuggestions?.map((e: any, i: number) => (
                        <StyledLink
                          key={`brand-${i}`}
                          testId={`brand-${e.name.toLowerCase()}`}
                          to={e.url}
                          onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                          <StyledMenuItem>
                            <StyledMenuTypography
                              className={e.arrIndex === index ? "active" : ""}
                              key={e.arrIndex}
                              id={`megamenu_department_${e.id}`}
                              title={e.name}>
                              {e.name}
                            </StyledMenuTypography>
                          </StyledMenuItem>
                        </StyledLink>
                      ))}
                    </>
                  ) : null}

                  {showSellers ? (
                    <>
                      <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                        {sellerTitle}
                      </StyledMenuTypography>
                      {sellerSuggestions?.map((e: any, i: number) => (
                        <StyledLink
                          key={`seller-${i}`}
                          testId={`seller-${e.name.toLowerCase()}`}
                          to={e.url}
                          onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                          <StyledMenuItem>
                            <StyledMenuTypography
                              className={e.arrIndex === index ? "active" : ""}
                              key={e.arrIndex}
                              id={`megamenu_department_${e.id}`}
                              title={e.name}>
                              {e.name}
                            </StyledMenuTypography>
                          </StyledMenuItem>
                        </StyledLink>
                      ))}
                    </>
                  ) : null}
                </ul>
              </StyledGrid>
              {firstThreeProducts.length > 0 ? (
                <StyledGrid className="suggestion-container" xs={12} md={8}>
                  <StyledMenuTypography variant="subtitle2" className="searchBar-resultsCategory">
                    Suggested products
                  </StyledMenuTypography>
                  {firstThreeProducts.map((product, index) => (
                    <StyledGrid container className="suggestion-product">
                      <div className="sugg-img-product">
                        <a href={product.url} rel="noopener noreferrer">
                          <img src={product.thumbnailImageUrl.replace(".jpg", "_aws_640.jpg")} alt="s" />
                        </a>
                      </div>
                      <StyledGrid className="sugg-prod-info">
                        <a href={product.url} title={product.name}>
                          <StyledGrid className="brand text-left">
                            {product.brand && `${product.brand}`.toUpperCase() === "AUGUSTA" ? (
                              <img
                                src="//static.augustasportswear.com/static/Icon-Listing-AugustaSportswear-color.png"
                                alt="Augusta"
                              />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "HIGH FIVE" ? (
                              <img
                                src="//static.augustasportswear.com/static/Icon-Listing-HighFive-color.png"
                                alt="High Five"
                              />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "HOLLOWAY" ? (
                              <img
                                src="//static.augustasportswear.com/static/Icon-Listing-Holloway-color.png"
                                alt="Holloway"
                              />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "RUSSELL" ? (
                              <img
                                src="//static.augustasportswear.com/static/Icon-Listing-RussellAthletic-color.png"
                                alt="Russell"
                              />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "PACIFIC HEADWEAR" ? (
                              <img
                                src="//static.augustasportswear.com/static/Icon-Listing-PacificHeadwear-color.png"
                                alt="Pacific Headwear"
                              />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "ALL VOLLEYBALL" ? (
                              <img
                                src="//static.augustasportswear.com/static/Icon-Listing-CustomFuze-color.png"
                                alt="Custom Fuze"
                              />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "CCM" ? (
                              <img src="//static.augustasportswear.com/static/ccm_50x16.svg" alt="CCM" />
                            ) : product.brand && `${product.brand}`.toUpperCase() === "PG" ? (
                              <img src="https://static.augustasportswear.com/static/pg_24x24.svg" alt="PG" />
                            ) : null}
                            <h6 className="MuiTypography-root sc-eDvSVe cTtETk brndNmeTxt MuiTypography-h6 padding-ty-10">
                              {product.name}
                            </h6>
                          </StyledGrid>
                          <StyledGrid className="brand text-left">
                            <StyledTypography variant="body2" className="bottom-margin-1">
                              #{product.styleId}
                            </StyledTypography>
                          </StyledGrid>
                          <StyledGrid container>
                            <StyledTypography className="product-price-container margin-left-2" variant="h6">
                              <span className="product-price padding-right-10">
                                <FormattedPriceDisplay min={product.price} />
                              </span>
                            </StyledTypography>
                            {Number(product.price) > 0 && Number(product.price) < Number(product.msrp_usd) && (
                              <>
                                <span className="strikethrough padding-right-10">
                                  {" "}
                                  <FormattedPriceDisplay min={product.msrp_usd} />
                                </span>
                                <span className="offer-percent">
                                  &#40;{" "}
                                  {(
                                    ((Number(product.msrp_usd) - Number(product.price)) / Number(product.msrp_usd)) *
                                    100
                                  ).toFixed(2)}
                                  % off&#41;
                                </span>
                              </>
                            )}
                          </StyledGrid>
                        </a>
                      </StyledGrid>
                    </StyledGrid>
                  ))}
                  <StyledButton testId="plp-goto-btn" color="secondary" className="shop-all" onClick={submitSearch}>
                    SHOP ALL
                  </StyledButton>
                </StyledGrid>
              ) : null}
            </StyledGrid>
          </ClickAwayListener>
        ) : null}
        {/* {!showKeywords && !showCategories && !showBrands && !showSellers && input.length > 4 ? (
          <div className="search-suggestion-display">
            <div className="search-suggested">
              <StyledTypography variant="h6" className="bottom-margin-2">
                Showing 0 Results
              </StyledTypography>
              <StyledTypography variant="h5" className="bottom-margin-1">
                Hmmm.... we could not find any results for {input}{" "}
              </StyledTypography>
            </div>
            <hr />
            <div className="searh-keyword-hints top-margin-2 bottom-margin-2">
              <span>
                <span className="material-icons icon">circle</span>Try diffrent search items
              </span>
              <span>
                <span className="material-icons icon">circle</span>Check your spelling
              </span>
              <span>
                <span className="material-icons icon">circle</span>Use exact phrases
              </span>
            </div>
          </div>
        ) : null} */}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
